import {createContext, useContext, useEffect, useState} from "react";
import {useEditor} from "../../../hook/editor.context";
import {useWebsocket, useWebsocketEvent} from "../../../../Socket/Socket.provider.ts";
import {useSnackbar} from "../../../../../Components/Snackbar/snackbar.context.ts";
import axios from "axios";
import {useParams} from "react-router-dom";
import {IEditorSession} from "shared/src/Entities/Editor/editorSession";
import {useAccount} from "../../../../Account/account.hook";
import {useCanvasSocketHandler} from "./useCanvasSocketHandler";

type sessionData = IEditorSession;

type LiveSessionContextType = {
    id: string|null;
    session: IEditorSession|null;
    startSession: () => Promise<IEditorSession>;
    joinSession: (session_id: string) => void;
    getCurrentSession: () => Promise<IEditorSession>;
}
export const LiveSessionContext = createContext<LiveSessionContextType|null>(null);
export function LiveSessionProvider({children}: {children: React.ReactNode}){
    const editor = useEditor();
    const socket = useWebsocket();
    const [sessionId, setSessionId] = useState<string|null>(null);
    const [session, setSession] = useState<sessionData|null>(null);
    const canvasSocketHandler = useCanvasSocketHandler();
    const snackbar = useSnackbar();
    const {token} = useParams();
    const user = useAccount();


    async function publishFullCanvas(_sessionId: string|null = sessionId){
        const canvas = editor.canvas;
        if (_sessionId == null) throw new Error("Session not found");
        if (canvas == null) throw new Error("Canvas not found");
        const data = canvas.toJSON(["name"]);
        await axios.post(`/api/editor/session/${_sessionId}/canvas`, data);
    }

    async function loadSessionCanvas(){
        const canvas = editor.canvas;
        if (canvas == null) throw new Error("Canvas not found");
        const data = await axios.get<IEditorSession>(`/api/editor/session/${sessionId}`).then(res => res.data);
       //load canvas with custom name attribute
        canvas.loadFromJSON(data.canvas, canvas.renderAll.bind(canvas), (o:any, object: any) => {
            object.set("name", o.name);
        });
    }

    async function startSession(){
        const session =  await axios.post<IEditorSession>("/api/editor/session").then(res => {
            console.log(`Session started: ${res.data.session_id}`)
            joinSession(res.data.session_id);
            return res.data;
        });

        await publishFullCanvas(session.session_id);

        return session;
    }


    async function joinSession(session_id: string) {
        await axios.post(`/api/editor/session/${session_id}/join`).then(res => {
            if (res.data.token== null) throw new Error("No token received, invalid session?");
            socket?.emit("exchange_join_request", res.data.token);
            setSessionId(session_id);
        });
    }

    async function getCurrentSession(): Promise<IEditorSession>{
       const _session = await axios.get<IEditorSession>(`/api/editor/session/${sessionId}`).then(res => res.data);
       setSession(_session);
       return _session;
    }

    useWebsocketEvent("session_user_joined", (data) => {
        snackbar(`Gebruiker ${data.Firstname} is toegetreden tot de sessie`);
        getCurrentSession();
    });

    useEffect(() => {
        if (session == null) return;
        if (session?.initiator?.Id==user?.Id) return;
        loadSessionCanvas();
    }, [session]);

    useEffect(() => {
        if (token == null) return;
        joinSession(token);
    }, [token]);




    return <LiveSessionContext.Provider value={{
        id: sessionId,
        session: session,
        startSession,
        joinSession,
        getCurrentSession
    }}>
        {children}
    </LiveSessionContext.Provider>
}


export function useLiveSession(){
    const context = useContext(LiveSessionContext);
    if (context == null) {
        throw new Error("useLiveSession must be used within a LiveSessionProvider");
    }
    return context;
}