import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {createContext, useContext, useState} from 'react';

export type DialogContextType = {
    openDialog(element?: JSX.Element): void
    setContent(content: JSX.Element): void
    setOpen(open: boolean): void
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export function DialogContextProvider({children}: { children: JSX.Element }) {
    const [content, setContent] = useState<JSX.Element | null>(null);
    const [open, setOpen] = useState(false);

    function openDialog(element: JSX.Element) {
        setContent(element);
        setOpen(true);
    }


    return <DialogContext.Provider value={{openDialog, setContent, setOpen}}>
        <Dialog open={open} onClose={() => setOpen(false)}>
            {content}
        </Dialog>
        {children}
    </DialogContext.Provider>
}

export function useConfirmDialog(title: string) {
    const context = useContext(DialogContext);
    if (!context) {
        throw new Error("useConfirmDialog must be used within a DialogContextProvider");
    }
    function confirm(content:string, onConfirm?: () => void, onDeny?: () => void) {
        context?.openDialog(<>
        <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{
                    context.setOpen(false);
                    onDeny?.();
                }} variant={"text"}>Annuleren</Button>
                <Button onClick={()=>{
                    context.setOpen(false);
                    onConfirm?.();
                }} variant={"contained"} autoFocus>
                    Doorgaan
                </Button>
            </DialogActions>
        </>);
    }

    return confirm;
}
