import { DragEventHandler, useMemo, useState } from 'react';
import { useFileUpload } from '../../../hooks/upload.hook';
import useSWR from 'swr';
import {
  Alert,
  Box,
  Card,
  CardMedia,
  LinearProgress,
  Skeleton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { SmartImage } from '../../Images/SmartImage';
import { IFile } from 'shared/src/Entities/File/file.interface';
type EventMockType = {
  target: {
    value: string;
  };
};
export type FileDropZoneProps = {
  defaultValue?: string;
  label?: string;

  // controlled
  onChange?: (event: EventMockType) => void;
  value?: string;
};

export function FileDropZone(props: FileDropZoneProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingDocument, setIsDraggingDocument] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { inputProps, formId, upload } = useFileUpload(true, true);
  const [_value, _setValue] = useState<string | undefined>(props.defaultValue);

  const isControlled = useMemo(() => props.value != null, [props.value]);

  const value = useMemo(
    () => (isControlled ? props.value : _value),
    [isControlled, props.value, _value]
  );

  const handleChange = (file: string) => {
    if (!isControlled) _setValue(file);
    props.onChange?.({
      target: {
        value: file,
      },
    });
  };

  const handleDragEnter: DragEventHandler = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver: DragEventHandler = (e) => {
    e.preventDefault();
  };

  const handleDragLeave: DragEventHandler = () => {
    setIsDragging(false);
  };

  const handleDrop: DragEventHandler = (e) => {
    e.preventDefault();
    setIsDragging(false);
    e.dataTransfer.dropEffect = 'copy';
    const files = e.dataTransfer.files;
    setIsUploading(true);
    upload(files)
      .then((id: string) => handleChange(id))
      .finally(() => setIsUploading(false));
    console.log(files);
  };

  return (
    <Box>
      <div
        className="space-y-4 sm:space-y-6"
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="flex w-full items-center justify-center">
          <label className="relative dark:hover:bg-bray-800 flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600">
            <Typography
              variant={'caption'}
              className="absolute left-1 -top-2 bg-white pr-2 pl-2"
            >
              {props.label}
            </Typography>
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              <svg
                className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Klik om te uploaden</span> of
                sleep en laat los
              </p>
              {/*<p className="text-xs text-gray-500 dark:text-gray-400">*/}
              {/*  PDF, TXT, EPUB, ODT of DOCX (max. 1000 mb)*/}
              {/*</p>*/}
              <div className={`mt-1 flex-row border-dashed border-gray-300`}>
                <div className={'flex flex-row'}>
                  {isUploading && <LinearProgress />}
                  {value && <UploadedFileList files={[value]} />}
                </div>
              </div>
            </div>
            <input
              {...inputProps}
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={(e) => {
                const files = e.target.files;
                if (!files) return;
                setIsUploading(true);
                upload(files)
                  .then((id: string) => handleChange(id))
                  .finally(() => setIsUploading(false));
              }}
            />
          </label>
        </div>
      </div>
    </Box>
  );
}

type UploadedFileListProps = {
  files: string[];
};

function UploadedFileList(props: UploadedFileListProps) {
  return (
    <Table size="small">
      <TableHead>
        <TableCell>Bestand</TableCell>
        <TableCell>Formaat</TableCell>
        <TableCell>Preview</TableCell>
      </TableHead>
      {props.files.map((file) => (
        <UploadedFileListRow key={file} fileId={file} />
      ))}
    </Table>
  );
}

function UploadedFileListRow(props: { fileId: string }) {
  const {
    data: file,
    isLoading,
    error,
  } = useSWR<IFile>(`/files/id/${props.fileId}`);

  if (isLoading) return <Skeleton />;
  if (error || file == null)
    return <Alert severity={'error'}>De preview kon niet worden geladen</Alert>;

  return (
    <TableRow>
      <TableCell>{file.name}</TableCell>
      <TableCell>{file.mime}</TableCell>
      <TableCell>
        <CardMedia sx={{ maxHeight: '5em' }}>
          <SmartImage
            src={file}
            imgProps={{
              style: {
                maxHeight: '5em',
                width: 'auto',
                objectFit: 'contain',
              },
            }}
          />
        </CardMedia>
      </TableCell>
    </TableRow>
  );
}
