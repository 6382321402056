import {Button, Card, CardContent, CardHeader, Divider, Grid} from "@mui/material";
import React, {useEffect} from "react";
import {CreateShipmentSchema, CreateShipmentType} from "shared/src/Modules/Shipments/Shipments.zod";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useAddressCompletion} from "../../../hooks/useAdressCompletion";
import {RHFTextfield} from "../../../Components/Form/RHF/RHFTextfield";
import {PrintJobTable} from "../Components/Prints/PrintJobTable";
import { useApi } from "apps/client/src/Components/Request/useApi";

export function Shipments() {
    const [updater, setUpdater] = React.useState(0);
    const forceUpdate = () => setUpdater(updater + 1);

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <CreateShipmentCard onSubmit={forceUpdate} />
            </Grid>
            <Grid item xs={6}>
                <PrintJobTable updater={updater}/>
            </Grid>
        </Grid>
    );
}

type CreateShipmentProps = {
    onSubmit: () => void;
}
export function CreateShipmentCard(props: CreateShipmentProps) {
    const api = useApi();

    const onSubmit: SubmitHandler<CreateShipmentType> = (data) => {
        api.post("/myparcel/shipment?print=true", data).then(props.onSubmit)
    }

    const methods = useForm<CreateShipmentType>({
        resolver: zodResolver(CreateShipmentSchema),
        defaultValues: {
            person: "",
            street: "",
            number: "",
            postalCode: "",
            city: "",
        }
    })

    const autoComplete = useAddressCompletion(methods.watch("postalCode"), methods.watch("number"));

    useEffect(() => {
        if (autoComplete) {
            methods.setValue("city", autoComplete.woonplaatsnaam);
            methods.setValue("street", autoComplete.straatnaam);
        }
    }, [autoComplete, methods]);

    return (
        <Card>
            <CardHeader title={"Nieuwe verzending"}/>
            <CardContent>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}> <RHFTextfield name={"person"} label={"Naam"}/></Grid>
                            <Grid item xs={6}>
                                <RHFTextfield name={"postalCode"} label={"Postcode"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <RHFTextfield name={"number"} label={"Huisnummer"}/>
                            </Grid>
                            <Divider/>
                            <Grid item xs={6}> <RHFTextfield name={"street"} label={"straat"}/></Grid>
                            <Grid item xs={6}> <RHFTextfield name={"city"} label={"Plaats"}/></Grid>
                            <Grid item xs={12}> <Button type={"submit"} variant={"contained"}> Aanmaken en uitprinten</Button></Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </CardContent>
        </Card>
    )
        ;
}

