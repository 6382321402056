import { Route } from 'react-router-dom';
import { LinearSuspense } from '../../Components/Suspenses/LinearSuspense';
import Scanner from './views/Scanner';
import FileUpload from './views/FileUpload';
import React from 'react';
import PrintHistoryTable from './views/TransactionTable';
import TransactionTable from './views/TransactionTable';
import CacheDiagnostics from './views/CacheDiagnostics';
import { TestView } from './views/TestView';
import OrganizationTable from './views/Organizations/OrganizationTable';
import ProductFamilyTable from '../Products/admin/ProductFamily/ProductFamilyTable';
import { Shipments } from './views/Shipments';
import { FileView } from './views/FileView';

const Dashboard = React.lazy(() => import('./views/Dashboard'));
const TasksView = React.lazy(() => import('./views/Tasks/TasksView'));
const UsersTable = React.lazy(() => import('./views/UsersTable'));
const LogTable = React.lazy(() => import('./views/LogTable'));
const MediaTabs = React.lazy(() => import('./views/MediaTabs/MediaTabs'));

export const adminRoutes = (
  <>
    <Route
      index
      element={
        <LinearSuspense>
          <Dashboard />
        </LinearSuspense>
      }
    />
    <Route
      path={'tasks'}
      element={
        <LinearSuspense>
          <TasksView />
        </LinearSuspense>
      }
    />
    <Route
      path={'gebruikers'}
      element={
        <LinearSuspense>
          <UsersTable />
        </LinearSuspense>
      }
    ></Route>
    <Route
      path={'media'}
      element={
        <LinearSuspense>
          <MediaTabs />
        </LinearSuspense>
      }
    ></Route>
    <Route
      path={'producten'}
      element={
        <LinearSuspense>
          <ProductFamilyTable />
        </LinearSuspense>
      }
    ></Route>
    <Route
      path={'logs'}
      element={
        <LinearSuspense>
          <LogTable />
        </LinearSuspense>
      }
    ></Route>
    <Route
      path={'printhistory'}
      element={
        <LinearSuspense>
          <PrintHistoryTable />
        </LinearSuspense>
      }
    ></Route>
    <Route
      path={'scanner'}
      element={
        <LinearSuspense>
          <Scanner />
        </LinearSuspense>
      }
    ></Route>
    <Route
      path={'upload'}
      element={
        <LinearSuspense>
          <FileUpload />
        </LinearSuspense>
      }
    ></Route>
    <Route
      path={'organizations'}
      element={
        <LinearSuspense>
          <OrganizationTable />
        </LinearSuspense>
      }
    ></Route>
    <Route
      path={'transactions'}
      element={
        <LinearSuspense>
          <TransactionTable />
        </LinearSuspense>
      }
    ></Route>
    <Route
      path={'cache'}
      element={
        <LinearSuspense>
          <CacheDiagnostics />
        </LinearSuspense>
      }
    ></Route>
    <Route
      path={'shipments'}
      element={
        <LinearSuspense>
          <Shipments />
        </LinearSuspense>
      }
    ></Route>
    <Route
      path={'files'}
      element={
        <LinearSuspense>
          <FileView />
        </LinearSuspense>
      }
    ></Route>
    <Route
      path={'test'}
      element={
        <LinearSuspense>
          <TestView />
        </LinearSuspense>
      }
    ></Route>
  </>
);
