import axios from "axios";
import {IUserEntity} from "shared/src/Entities/user.interface";
import {UserLoginDto, UserRegisterDto} from "shared/src/Models/user.types";
import {SharedConfig} from "shared/src/shared.config";

function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

const vapidPublicKey = "BB4r7iD_ndBgLSzHNtyaWOGig2VkQDR9TZ4roiutTYJjyE9keGAWQTfxgNBMkBoocHujelnJPNsSGw2sXiZDtjw";
const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

class _UserController {

    async Login(loginDTO: UserLoginDto) {
        const token = await axios.post<{ token: string }>(`${SharedConfig.api.url}/users/login`, loginDTO);
        //this.setToken(user.data);
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.ready.then(async (sw) => {
                const subscription = await sw.pushManager.getSubscription().then((sub) => {
                    if (sub) return sub;
                    return sw.pushManager.subscribe({userVisibleOnly: true, applicationServerKey: convertedVapidKey})

                });
                if (!subscription) return;
                const subJson = subscription.toJSON();
                const dto = {
                    endpoint: subJson.endpoint,
                    auth: subJson.keys?.auth,
                    p256dh: subJson.keys?.p256dh
                }
                await axios.put(`/api/push`, dto);
            })

        }
        return
    }

    async Logout() {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.ready.then(async (sw) => {
                const subscription = await sw.pushManager.getSubscription();
                if (!subscription) return;
                const subJson = subscription.toJSON();
                const dto = {
                    endpoint: subJson.endpoint,
                    auth: subJson.keys?.auth,
                    p256dh: subJson.keys?.p256dh
                }
                await axios.delete(`/api/push`, {data: dto});
            })

        }
        return await axios.post<boolean>(`${SharedConfig.api.url}/users/logout`);
    }

    async GetOrCreateCurrentUser() {
        return await this.getCurrentUser()
    }

    async getCurrentUser(): Promise<IUserEntity> {
        const user = await axios.get(`${SharedConfig.api.url}/users/me`);
        return user.data;
    }

    async Register(registerDTO: UserRegisterDto): Promise<IUserEntity> {
        const response = await axios.post(`${SharedConfig.api.url}/users/register`, registerDTO);
        return response.data;
    }

}

const UserController = new _UserController();
export default UserController;