import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { z, ZodObject, ZodRawShape } from 'zod';
import React, { useMemo } from 'react';
import { useZodFormContext, ZodFormProvider } from './context/ZodFormContext';
import { RHFTextfield } from '../Form/RHF/RHFTextfield';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { zGetDefaults } from 'shared/src/Utility/getZodDefaults';
import { useSnackbar } from '../Snackbar/snackbar.context.ts';

//export type FieldProperties = z.infer<typeof FieldPropertiesSchema>

export type FieldProperties = {
  hidden?: boolean;
  component?: React.ReactNode;
  defaultValue?: any;
  readonly?: boolean;
  label?: string;
  grid?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
};

const defaultFieldProperties: FieldProperties = {
  hidden: false,
  component: undefined,
  defaultValue: undefined,
  readonly: false,
  label: undefined,
  grid: {
    xs: 12,
  },
};
export type ZodFormFieldsType<A> = { [key in keyof A]?: FieldProperties };

export type ZodFormProps<A extends ZodRawShape> = {
  schema: ZodObject<A>;
  fields?: ZodFormFieldsType<A>;
  onSubmit: (data: z.infer<ZodObject<A>>) => Promise<void>;
  toEdit?: z.infer<ZodObject<A>>;
  onSuccess?: () => void;
  defaultValues?: Partial<z.infer<ZodObject<A>>>;
};

export function ZodForm<A extends ZodRawShape>(props: ZodFormProps<A>) {
  const snackbar = useSnackbar();

  const defaultValues = useMemo(
    () =>
      props.toEdit
        ? props.schema.parse(props.toEdit)
        : { ...zGetDefaults(props.schema) },
    [props.toEdit]
  );

  const methods = useForm({
    resolver: zodResolver(props.schema),
    defaultValues: defaultValues,
  });

  function handleSubmit(data: { [p: string]: any }) {
    console.log('submit');
    props
      .onSubmit(data as z.infer<ZodObject<A>>)
      .then(() => {
        props.onSuccess != undefined ? props.onSuccess() : snackbar('Success');
      })
      .catch(() => snackbar('Er is iets misgegaan'));
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleSubmit, (errors) => {
          console.log(errors);
        })}
      >
        <ZodFormProvider<A> props={props}>
          <ZodFormRenderer />
        </ZodFormProvider>
      </form>
    </FormProvider>
  );
}

export function ZodFormRenderer<A extends ZodRawShape>() {
  const { props } = useZodFormContext<A>();
  const formComponents = getFormComponents();

  return (
    <Grid container spacing={1}>
      {formComponents}
      <Grid item xs={12}>
        <Button type={'submit'} variant={'contained'}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}

function getFormComponents<A extends ZodRawShape>() {
  const components: any[] = [];
  const {
    props: { schema },
  } = useZodFormContext();

  for (const key in schema.shape) {
    const field = schema.shape[key];
    components.push(getComponentForField<A>(key, field));
  }
  return components;
}

function getComponentForField<A extends ZodRawShape>(
  name: string,
  _field: any,
  _defaultValue?: any
) {
  const { props } = useZodFormContext<A>();
  //parse field and load default property values
  const fieldProperties = {
    ...defaultFieldProperties,
    ...props.fields?.[name],
  };
  const gridSizes = useMemo(
    () => ({
      xs: fieldProperties.grid?.xs ?? 12,
      sm: fieldProperties.grid?.sm,
      md: fieldProperties.grid?.md,
      lg: fieldProperties.grid?.lg,
      xl: fieldProperties.grid?.xl,
    }),
    [fieldProperties.grid]
  );
  if (fieldProperties.hidden) return null;
  if (fieldProperties.component) return fieldProperties.component;
  const disabled = fieldProperties.readonly;

  const defaultValue = _defaultValue ?? fieldProperties.defaultValue;

  let field = _field;
  if (_field.unwrap) field = _field.unwrap();

  switch (field._def.typeName) {
    case 'ZodString':
    case 'ZodNumber':
      return (
        <Grid item {...gridSizes}>
          <RHFTextfield
            disabled={disabled}
            defaultValue={defaultValue}
            label={name}
            name={name}
          />
        </Grid>
      );
      break;
    case 'ZodBoolean':
      return (
        <Grid item {...gridSizes}>
          {' '}
          <FormControlLabel
            disabled={fieldProperties.readonly}
            control={<Checkbox defaultChecked={defaultValue} />}
            label={<Typography>{name}</Typography>}
          />
        </Grid>
      );
      break;
    case 'ZodDefault':
      return getComponentForField(
        name,
        field._def.innerType,
        field._def.defaultValue()
      );
      break;
    case 'ZodEnum':
      return (
        <Grid item {...gridSizes}>
          {' '}
          <Select sx={{ width: '100%' }}>
            {field._def.values.map((value: any) => (
              <MenuItem value={value}>{value}</MenuItem>
            ))}
          </Select>
        </Grid>
      );
      break;
    default:
      console.error(
        'No component found for field',
        field._def.typeName,
        field._def
      );
      return (
        <Grid item {...gridSizes}>
          <RHFTextfield
            disabled={fieldProperties.readonly}
            defaultValue={defaultValue}
            label={name}
            name={name}
          />
        </Grid>
      );
      break;
  }
}
