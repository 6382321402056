import React, {useEffect} from "react";
import useSWR from "swr";
import axios from "axios";
import {Alert, CircularProgress, Divider, Grid, LinearProgress, Stack, TextField} from "@mui/material";
import {LinkableTextfield} from "./LinkableTextfield";
import {IMalfiniProduct} from "shared/src/Interfaces/IMalfiniProduct";
import {RHFTextfield} from "../../../Components/Form/RHF/RHFTextfield";

export function MalfiniProductInformation({prod}: { prod: any }) {
    const [generatedDescription, setGeneratedDescription] = React.useState<string>("");
    const [extendedProductInfo, setExtendedProductInfo] = React.useState<any>(null);

    const {data: product, error, isLoading, mutate} = useSWR<IMalfiniProduct>(`/malfini/product/${prod.seoName}`);
    useEffect(() => {
        if (product == null) return;
        axios.get(`/api/ai/productDescription`, {
            params: {
                prompt: product.description
            }
        }).then(res => {
            setGeneratedDescription((res.data.choices[0].message.content as string).trim());
        });
    }, [product]);
    console.log("product.colors", product?.colors)
    if (isLoading) return <CircularProgress/>

    if (error || product == null) return <Alert severity={"error"}>Er is een fout opgetreden</Alert>

    return <Stack spacing={2}>
        {/*<TextField margin={"normal"} label={"Naam"} value={product?.name}/>*/}
        <RHFTextfield name={"name"} label={"Naam"} defaultValue={product?.name}/>
        <RHFTextfield name={"slug"} label={"Slug"} defaultValue={product?.seoName}/>
        <RHFTextfield name={"price"} label={"Prijs"}/>
        <Divider/>
        {/*<TextField sx={{margin:"1em"}} label={"Kleuren"} value={product?.colors.reduce((prev:string, cur:any)=>{return `${prev}, ${cur.name}`},"")}/>*/}
        <TextField margin={"normal"} label={"Maten"} value={product?.sizes}/>
        <Grid container spacing={1}>
            {product?.colors?.find((item) => item.collection == null)?.items.map((color: any, index: number) => {
                console.log("color", color)
                return <Grid item><img style={{width: "100%"}}
                                       src={`https://shop.malfini.com/image/product/${product.code}/${product.code}_${color.code}_a~w100.jpg`}/></Grid>
                // return <Grid item><Chip label={color.name}></Chip></Grid>

            })}
        </Grid>
        {generatedDescription == "" ? <LinearProgress/> :
            <TextField multiline onChange={(e) => setGeneratedDescription(e.target.value)} margin={"normal"}
                       value={generatedDescription}></TextField>}

        <LinkableTextfield margin={"normal"} label={"Maattabel"}
                           value={`https://shop.malfini.com/file/${product?.sizeChartPdf}`}/>
    </Stack>
}