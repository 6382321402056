import {IsEmail, IsStrongPassword, MinLength} from "class-validator";
import {IsEqualTo} from "../Decorators/isEqual.decorator";

export class UserRegisterDto {
    @IsEmail()
    Email: string;
    @MinLength(2)
    Firstname?: string;
    @MinLength(2)
    Lastname?: string;
    @IsStrongPassword()
    Password: string;

    @IsEqualTo<UserRegisterDto>("Password")
    ConfirmPassword: string;

}

export class UserLoginDto {
    @IsEmail()
    Email: string;
    Password: string;
}

export enum AccountType {
    Guest = "guest",
    Default = "default",
    Admin = "admin",
    Business = "business",
    Service = "service",
    Taekwondo = "taekwondo",
}

export interface IUser {
    Guid: string,
    Id: number,
    AccountType: string,
    Firstname?: string,
    Lastname?: string,
    Email?: string,
    Password?: string
}