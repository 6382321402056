import {Alert, Collapse} from "@mui/material";
import React from "react";

interface Props {
    errors?: string | string[] | null,
    children?: any,
    severity?: "error" | "warning" | "info" | "success"
}

export default function Errors({errors, children, severity}: Props) {
    return errors == null ? <>{children}</> : <Collapse in={true}><Alert severity={severity ?? "error"}>
        {
            Array.isArray(errors) ? <ul>
                {errors.map((error, index) => {
                    return <li key={index}>{error}</li>
                })}
            </ul> : errors
        }
    </Alert>
    </Collapse>

}