import React, { useCallback } from 'react';
import { UpsertPropertyFamilyDialog } from './Properties/UpsertPropertyFamily';
import { UpsertProductDialog } from './UpsertProduct';
import {
  Alert,
  Button,
  Chip,
  Grid,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { Add, Refresh } from '@mui/icons-material';
import { TabPanel } from '../../../Components/Tabs/TabPanel';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IProduct } from 'shared/src/Entities/Product/Product/Product.interface';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { useProductFamily } from '../hooks/products.hook.ts';
import { SmartImage } from 'apps/client/src/Components/Images/SmartImage';

export function ProductFamilyProducts({ id }: { id: string }) {
  const { productFamily, productFamilyLoading, mutate } = useProductFamily(id, [
    'Products',
    'Products.Properties',
    'Products.Thumbnail',
  ]);
  const [addingProduct, setAddingProduct] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [refreshingStock, setRefreshingStock] = React.useState(false);

  const columns: GridColDef[] = React.useMemo<GridColDef<IProduct>[]>(
    () => [
      {
        field: 'thumb',
        headerName: 'Thumb',
        renderCell: ({ row }) => (
          <SmartImage
            src={row.Thumbnail}
            imgProps={{ style: { maxHeight: '100%' } }}
          />
        ),
      },
      { field: 'Slug', headerName: 'Slug', flex: 1 },
      ...(productFamily?.PropertyFamilies ?? []).map((item, index) => {
        return {
          field: item.DisplayName,
          headerName: item.DisplayName,
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            console.log(params.row);
            return params.row.Properties[index].DisplayName;
          },
        };
      }),
      {
        field: 'Stock',
        headerName: 'Voorraad',
        flex: 1,
        editable: true,
        valueSetter: (params) => {
          updateProduct(params.row);
          return params.row;
        },
      },
      { field: 'ExternalStock', headerName: 'Externe voorraad', flex: 1 },
    ],
    [productFamily]
  );

  const updateProduct = useCallback((product: IProduct) => {
    axios.post('/api/malfini/updateProduct', { product }).finally(() => {
      mutate();
    });
  }, []);

  const updateStock = useCallback(() => {
    setRefreshingStock(true);
    axios.post('/api/malfini/updateStock', { family: id }).finally(() => {
      setRefreshingStock(false);
      mutate();
    });
  }, [id]);

  if (productFamilyLoading) return <LinearProgress />;

  return (
    <Paper sx={{ marginTop: '2em' }}>
      <Grid container spacing={2} sx={{ padding: '1em' }}>
        <Grid item>
          <Button
            size={'small'}
            variant={'contained'}
            onClick={() => {
              setAddingProduct(true);
            }}
            startIcon={<Add />}
          >
            {' '}
            Product
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            loading={refreshingStock}
            size={'small'}
            variant={'contained'}
            onClick={updateStock}
            startIcon={<Refresh />}
          >
            {' '}
            Update voorraad
          </LoadingButton>
        </Grid>
      </Grid>
      <DataGrid
        columns={columns}
        rows={(productFamily?.Products as IProduct[]) ?? []}
        getRowId={(row: IProduct) => row.Id}
      />
    </Paper>
  );
}
