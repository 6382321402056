import {createContext, useContext, useEffect} from "react";
import io, {Socket} from "socket.io-client";
import Config from "../../Config";
import {SharedConfig} from "shared/src/shared.config";

const SocketContext = createContext<Socket | null>(null);


export function WebsocketProvider({children}: { children: React.ReactNode }) {
    const socket = io(SharedConfig.public.websocket);
    socket.on("connect", () => {
        console.log("Connected to websocket");
    });

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
}

export function useWebsocket() {
    return useContext(SocketContext);
}


export function useWebsocketEvent(name: string, callback: (...args: any[]) => void) {
    const socket = useContext(SocketContext);
    if (!socket) throw new Error("Socket not initialized");
    useEffect(() => {
        if (!socket) return;
        socket.on(name, callback);
        console.log("Registered event", name);
        return () => {
            socket.off(name, callback);
        }
    }, [socket, name, callback]);

    return socket;
}