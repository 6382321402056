import React from 'react';
import { useMobile } from '../../../hooks/mobile.hook.ts';
import { CacheStatusCard } from '../Components/CacheStatus';
import { Button, Grid, Card, CardActions, CardHeader } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from '../../../Components/Snackbar/snackbar.context.ts';

export default function CacheDiagnostics() {
  const mobile = useMobile();
  const snackbar = useSnackbar();
  return (
    <Grid container spacing={1}>
      <Grid item>
        <CacheStatusCard />
      </Grid>
      <Grid item>
        <Card>
          <CardHeader title={'API'} />
          <CardActions>
            <Button
              onClick={() => {
                axios
                  .put('/api/pm2/restart')
                  .then(() => {
                    snackbar('Restarting API');
                  })
                  .catch(() => {
                    snackbar('Restarting API');
                  });
              }}
            >
              Restart API
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item>
        <Card>
          <CardHeader title={'Files'} />
          <CardActions>
            <Button
              onClick={() => {
                axios
                  .post('/api/files/disconnected/purge')
                  .then((resp) => {
                    snackbar(`Purged ${resp.data} files.`);
                  })
                  .catch(() => {
                    snackbar('Restarting API');
                  });
              }}
            >
              Purge disconnected files
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
