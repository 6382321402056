import {IconButton, InputAdornment, TextField, TextFieldProps} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import React, {useState} from "react";


export function PasswordField(props: TextFieldProps) {
    const [visible, setVisible] = useState(false);

    function handleVisibleclick() {
        setVisible(!visible);
    }

    return <TextField {...props} type={visible ? "text" : "password"} InputProps={{
        endAdornment: <InputAdornment position={"end"}><IconButton onPointerDown={() => setVisible(true)}
                                                                   onPointerLeave={() => setVisible(false)}
                                                                   onPointerUp={() => setVisible(false)}>{visible ?
            <Visibility/> : <VisibilityOff/>}</IconButton></InputAdornment>
    }
    }/>
}