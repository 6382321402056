import {createContext, useEffect, useState} from "react";
import {ICompanyEntity} from "shared/src/Entities/Company/company.entity.interface";
import {useAccount} from "../../Account/account.hook";

type OrganizationContextProps = {
    selectedOrganizationId?: string;
    setSelectedOrganizationId: (organization: string) => void;
    availableOrganizations: ICompanyEntity[];
    selectedOrganization?: ICompanyEntity;
}
export const OrganizationContext = createContext<OrganizationContextProps|undefined>(undefined);

export function OrganizationProvider({children}: {children:React.ReactNode}){
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<string|undefined>(undefined);
    const [selectedOrganization, setSelectedOrganization] = useState<ICompanyEntity|undefined>(undefined);
    const user = useAccount();

    useEffect(() => {
        if (selectedOrganizationId) return;
        if (!user?.Memberships){ setSelectedOrganizationId(undefined); return;}
        if(user.Memberships.length > 0) setSelectedOrganizationId(user.Memberships[0].Organization.Semantic);

    }, [user?.Memberships]);

    useEffect(() => {
        if (!user?.Memberships) return;
        setSelectedOrganization(user?.Memberships.find(membership => membership.Organization.Semantic == selectedOrganizationId)?.Organization);
    }, [selectedOrganizationId]);

    return <OrganizationContext.Provider value={{
        selectedOrganizationId,
        setSelectedOrganizationId,
        selectedOrganization,
        availableOrganizations: []
    }}>
        {children
        }
    </OrganizationContext.Provider>

}
