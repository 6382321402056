import axios, {AxiosInstance} from "axios";
import { createContext, useMemo } from "react";

type ApiContextType = {
    axios: AxiosInstance;
};

export const apiContext = createContext<ApiContextType | undefined>(undefined);

type ApiProviderProps = {
    children: React.ReactNode;
}
export const ApiProvider: React.FC<ApiProviderProps> = ({children}) => {

    const axiosInstance = useMemo(()=>{
        return axios.create({
            baseURL: "/api",
        })
    },[])

    const value = useMemo(() => ({
        axios: axiosInstance
    }),[axiosInstance])


    return <apiContext.Provider value={value}>
        {children}
    </apiContext.Provider>
}


