import {useContext} from "react";
import UserContext from "./account.context";


export function useAccount() {
    const context = useContext(UserContext);
    if (context == null) throw new Error("useAccountActions must be used within a UserProvider");
    return context.User;
}

export function useAccountActions() {
    const context = useContext(UserContext);
    if (context == null) throw new Error("useAccountActions must be used within a UserProvider");
    return context;
}