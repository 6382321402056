import userController from "../../Controllers/UserController";
import React, {useEffect, useState} from "react";
import {Alert, LinearProgress} from "@mui/material";
import UserContext from "./account.context";
import {IUserEntity} from "shared/src/Entities/user.interface";

function useUserFetcher() {
    const [user, setUser] = useState<IUserEntity | null>(null);

    async function getCurrentUser() {
        await userController.GetOrCreateCurrentUser().then((user) => setUser(user));
    }

    function requestNewUser() {
        //userController.setToken(null);
        userController.Logout().then(() => {
            getCurrentUser();
        })

    }

    return {User: user, Fetch: getCurrentUser, Logout: requestNewUser};
}

export function AccountProvider({children}: { children: JSX.Element }) {
    const fetcher = useUserFetcher();
    const [error, setError] = useState<boolean>(false);
    useEffect(() => {
        fetcher.Fetch().catch((e) => {
            setError(e);
        });
    }, []);
    if (error) {
        return <div className={"splash"}>
            <Alert severity={"error"}>
                Er is een fout opgetreden bij het ophalen van gegevens vanaf de server. Probeer het later opnieuw.
            </Alert>
        </div>
    }
    return <UserContext.Provider value={fetcher}>
        {fetcher.User == null ? <div className={"splash"}><LinearProgress/></div> : children}
    </UserContext.Provider>
}