import React, {useEffect, useState} from "react";
import {
    Button, DialogContent,
    Container, Dialog, DialogActions, DialogContentText, DialogTitle,
    Grid,
    Paper,
    TextField,
    useTheme, Select, MenuItem, Stack, FormGroup, FormLabel, InputLabel, FormControl,
} from "@mui/material";

import {useWebsocketEvent} from "../../Socket/Socket.provider.ts";
import {ScannerMode} from "shared/src/Entities/Barcode/scannermode.enum";
import useSWR from "swr";
import axios from "axios";


export default function Dashboard() {
    const [barcode, setBarcode] = useState<string>("");
    const theme = useTheme();
    const [message, setMessage] = useState<string>("");
    const [mode, setMode] = useState<ScannerMode>(ScannerMode.REMOVE);
    const {data, isLoading} = useSWR("/scanner/mode")

    useEffect(() => {
        setMode(data?.mode ?? ScannerMode.REMOVE)
    }, [data]);

    useWebsocketEvent("barcode", (data: any) => {
        console.log(data)
        setBarcode(data)
    });

    useWebsocketEvent("barcode_message", (data: any) => {
        setMessage(data)
    });

    const handleModeChange = (event: any) => {
        console.log(event.target.value);
        setMode(event.target.value);
        axios.post("/api/scanner/mode", {mode: event.target.value})
    }

    return <>
        <Dialog open={message != ""}>
            <DialogTitle>Barcode scanner</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setMessage("")
                }} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
        <Paper sx={{padding: "1em"}}>
            <Grid container spacing={2}>
                <Grid item>

                    <TextField disabled label={"barcode"} value={barcode}/>
                </Grid>
                <Grid item xs={3}>
                    <FormControl sx={{minWidth: "100%"}}>
                        <InputLabel id={"scanner-mode-label"}>Scanner mode</InputLabel>
                        <Select value={mode} onChange={handleModeChange} autoWidth label={"Scanner mode"}
                                labelId={"scanner-mode-label"}>
                            <MenuItem value={ScannerMode.REMOVE}>Afschrijven</MenuItem>
                            <MenuItem value={ScannerMode.ADD}>Opboeken</MenuItem>
                            <MenuItem value={ScannerMode.CHECK}>Controleren</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Paper>
    </>
}


