import { IProduct } from 'shared/src/Entities/Product/Product/Product.interface';
import useSWR from 'swr';
import { ProductFamilyType } from 'shared/src/Entities/Product/ProductFamily/ProductFamily.interface';

export function useProduct(productID: string | undefined) {
  const {
    data: product,
    error,
    isLoading: productLoading,
  } = useSWR<IProduct>(productID == null ? null : `/products/${productID}`);
  console.log('prod', product);
  return { product, productLoading, error };
}

export function useProductFamilies(
  withInactive = false,
  relations: string[] = []
) {
  const query = relations.reduce((acc, cur) => acc + `${cur},`, '?relations=');
  const {
    data: productFamilies,
    error,
    isLoading: productFamiliesLoading,
    mutate,
  } = useSWR<ProductFamilyType[]>(
    `/products/families${withInactive ? '/all' : ''}${query}`
  );
  return { productFamilies, productFamiliesLoading, error, mutate };
}

export function useProductFamily(
  slug: string | undefined | number,
  relations: string[] = []
) {
  const query = relations.reduce((acc, cur) => acc + `${cur},`, '?relations=');

  const {
    data: productFamily,
    error,
    isLoading: productFamilyLoading,
    mutate,
  } = useSWR<ProductFamilyType>(`/v1/products/families/${slug}${query}`);
  return { productFamily, productFamilyLoading, error, mutate };
}
