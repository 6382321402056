import {TabDefinition} from "./TabDefinition";
import React, {useEffect} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import {TabPanel} from "./TabPanel";


type TabbableProps = {
    tabs: TabDefinition[];
    currentTab?: number;
    defaultTab?: number;
    onTabChange?: (tab: number) => void;
}

export function Tabbable({tabs, ...props}: TabbableProps) {
    const [currentTab, setCurrentTab] = React.useState(props.defaultTab ?? 0);

    useEffect(() => {
        if(props.currentTab !== undefined && props.currentTab !== currentTab) setCurrentTab(props.currentTab)
    }, [props.currentTab]);

    function changeTab(tab: number){
        if (tab === currentTab) return;
        setCurrentTab(tab);
        if (props.onTabChange) props.onTabChange(tab);
    }

    return <Box>
        <Tabs value={currentTab} onChange={(e, value) => changeTab(value)}>
            {tabs.map((tab, index) => {
                return <Tab key={index} label={tab.label} hidden={tab.hidden?tab.hidden():false} disabled={tab.disabled ? tab.disabled() : false}/>
            })}
        </Tabs>
        {
            tabs.map((tab, index) => <TabPanel value={currentTab} index={index} key={index}>
                {tab.panel}
            </TabPanel>)
        }
    </Box>
}