import {Autocomplete, Box, Button, CircularProgress, Stack, TextField} from "@mui/material";
import React, {useEffect} from "react";
import useSWR from "swr";
import axios from "axios";
import {IMalfiniProduct} from "shared/src/Interfaces/IMalfiniProduct";
import {MalfiniProductInformation} from "./MalfiniProductInformation";
import {zodResolver} from "@hookform/resolvers/zod";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {
    ImportMalfiniProductSchema,
    ImportMalfiniProductType
} from "shared/src/Modules/Malfini/ImportMalfiniProductSchema";


export function MalfiniWizard() {
    const [query, setQuery] = React.useState<string>("");
    const [selectedProduct, setSelectedProduct] = React.useState<IMalfiniProduct | null>(null);
    const {
        data: products,
        error,
        isLoading
    } = useSWR(query.length <= 1 ? null : `/malfini/searchProduct?query=${query}`);

    const methods = useForm<ImportMalfiniProductType>({
        resolver: zodResolver(ImportMalfiniProductSchema)
    })

    const handleSubmit: SubmitHandler<ImportMalfiniProductType> = (data) => {
        axios.post(`/api/malfini/import`, data)
    }

    useEffect(() => {
        if (!selectedProduct) return;
        methods.setValue("name", selectedProduct.name)
        methods.setValue("identifier", selectedProduct.code)
    }, [selectedProduct]);

    return <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <Box>
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Autocomplete onChange={(e, val) => setSelectedProduct(val)} onInputChange={(e, value) => {
                    setQuery(value)
                }} getOptionLabel={(option: any) => `${option.name} (${option.code})`}
                              options={products?.products ?? []}
                              renderInput={(params) => <TextField {...params} label="Product" InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                      <React.Fragment>
                                          {isLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                          {params.InputProps.endAdornment}
                                      </React.Fragment>
                                  ),
                              }}/>}/>

                {selectedProduct && <MalfiniProductInformation prod={selectedProduct}/>}

                <Stack direction={"row-reverse"} spacing={2}>
                    <Button variant={"contained"} type={"submit"}>Importeren</Button>
                </Stack>
            </Box>
        </form>
    </FormProvider>
}


