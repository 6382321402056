import {useParams} from "react-router-dom";
import {IUploadEntity} from "shared/src/Entities/Upload/Upload.interface";
import useSWR from "swr";
import React from "react";
import {Alert, Grid, LinearProgress, Paper, Skeleton, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import {useSnackbar} from "../Components/Snackbar/snackbar.context.ts";
import axios from "axios";

export function FileUploadClient() {
    const token = useParams<{ token: string }>().token;
    const {data, error, isLoading} = useSWR<IUploadEntity>(`/upload/link/${token}`);
    const [uploading, setUploading] = React.useState(false);
    const snackbar = useSnackbar();
    const [file, setFile] = React.useState<File|null>(null);
    const [status, setStatus] = React.useState<React.ReactNode>(null);
    const handleFilePicked = (files: FileList|null) => {
        if (files === null) return;
        setStatus(<LinearProgress/>)
        const file = files[0];
        setFile(file)
        console.log(file);
        setUploading(true);
        if (data?.UploadUrl === undefined) throw new Error("Upload url is undefined");
        axios.put(data?.UploadUrl, file, {
            headers: {
                "Content-Type": file.type
            }
        }).then(()=> {
            axios.post(`/api/upload/complete`, {token: data.Token, filename: file.name, mimetype: file.type}).then(() => {
                setStatus(<Alert severity={"success"}>Bestand geupload</Alert>)
            }).catch(()=>{
                setStatus(<Alert severity={"error"}>Bestand kon niet worden geupload</Alert>)
            });

        }).catch(err => {
            snackbar("Er is iets misgegaan");
            setStatus(<Alert severity={"error"}>Bestand kon niet worden geupload</Alert>)
        }).finally(() => {
            setUploading(false);
        });
    }


    if (isLoading) return <Skeleton/>

    if (error?.response?.status===404) return <Alert severity={"error"}>Deze link is niet (meer) geldig</Alert>
    if (error) throw new Error(error.message);
    if (data?.Completed) return <Alert severity={"info"}>Deze link is al een keer gebruikt.</Alert>
    return <Container component={Paper} sx={{marginTop: "1em", padding:"2em"}}>
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <h5 className={"text-xl font-extrabold"}>Bestand uploaden</h5>
                <p className={"text-lg"}>{data?.Description}</p>
                <p className={"text-sm mt-auto text-gray-500"}>Door deze link te gebruiken kan voorkomen worden dat afbeeldingen gecomprimeerd worden, zo garanderen wij de hoogste kwaliteit upload.</p>
            </Grid>
            <Grid item xs={6}>
                {file==null?
                <div className="space-y-4 sm:space-y-6">
                    <div className="mt-7 flex w-full items-center justify-center">
                        <label className="dark:hover:bg-bray-800 flex h-44 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                <svg
                                    className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 16"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                    />
                                </svg>
                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">
                          Klik om te uploaden
                        </span>{" "}
                                    of sleep en laat los
                                </p>
                                <p className="text-xs text-gray-500 dark:text-gray-400">
                                    PDF, TXT, EPUB, ODT of DOCX (max. 1000 mb)
                                </p>
                                <div
                                    className={`mt-1 flex-row border-dashed border-gray-300`}
                                >
                                    <div className={"flex flex-row"}>
                                    </div>
                                </div>
                            </div>
                            <input
                                id="dropzone-file"
                                type="file"
                                className="hidden"
                                onChange={(e) => { handleFilePicked(e.target.files)}}
                            />
                        </label>
                    </div>
                </div>:
                    <div className={"flex flex-col"}>
                        {status}
                        <Typography variant={"h6"}>Bestand</Typography>
                        <Typography variant={"body1"}>{file.name}</Typography>
                        <Typography variant={"h6"}>Grootte</Typography>
                        <Typography variant={"body1"}>{file.size}</Typography>
                        <Typography variant={"h6"}>Type</Typography>
                        <Typography variant={"body1"}>{file.type}</Typography>
                    </div>

                }


            </Grid>
            <Grid item xs={12}>
            </Grid>
        </Grid>


    </Container>
}