import { z } from 'zod';
import { zSlug } from '../../../ZodTypes/slug';
import { ProductFamilyStatus } from '../../../Modules/Product/ProductFamilyStatus.enum';

export const ProductFamilyCreateSchema = z.object({
  Id: z.coerce.number().optional().describe('Id'),
  Name: z
    .string({ required_error: 'Naam mag niet leeg zijn.' })
    .min(3)
    .max(255),
  Slug: zSlug,
  Description: z
    .string({ required_error: 'Omschrijving mag niet leeg zijn.' })
    .min(3)
    .max(255),
  Status: z
    .nativeEnum(ProductFamilyStatus)
    .default(ProductFamilyStatus.DISABLED),
  Price: z.coerce.number().default(12),
  ThumbnailId: z.string().optional(),
  CategoryIds: z.array(z.number()).optional(),
  PropertyFamilyIds: z.array(z.number()).optional(),
  AttachmentIds: z.array(z.number()).optional(),
  ExternalProvider: z.coerce.string().optional(),
});

export type ProductFamilyCreateType = z.infer<typeof ProductFamilyCreateSchema>;
