import React from "react";


interface ISpeedDialContext {
    Components: any,
    SetComponents: (components: any) => void
}

const SpeedDialContext = React.createContext<ISpeedDialContext | null>(null);


export default SpeedDialContext;