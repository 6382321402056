import useSWR from 'swr';
import { AccountType } from 'shared/src/Models/user.types';
import Errors from '../Components/Errors/DisplayErrors';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Slide from '@mui/material/Slide';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import React, { useState } from 'react';
import { useSpeedDial } from '../Wrapper';
import { LibraryAdd } from '@mui/icons-material';
import RolesOnly from '../Components/Restricted/RolesOnly';
import { ICategory } from 'shared/src/Entities/Category/category.interface';

export default function Shop() {
  const { data, error, isLoading, mutate } = useSWR<ICategory[]>(
    '/categories/listable'
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  useSpeedDial([
    <RolesOnly roles={[AccountType.Admin]}>
      <SpeedDialAction
        icon={<LibraryAdd />}
        onClick={() => {
          setDialogOpen(true);
        }}
        tooltipTitle={'Categorie toevoegen'}
      />
    </RolesOnly>,
  ]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    mutate();
  };

  if (data == null || isLoading) return <Skeleton sx={{ width: '100%' }} />;

  return (
    <Slide in={true} appear={true} direction={'right'}>
      <Container>
        <Errors errors={error}>
          {/*<ListableList listables={data}/> TODO: update*/}
        </Errors>
      </Container>
    </Slide>
  );
}
