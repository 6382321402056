import {createContext, useContext} from "react";
import {ZodFormProps} from "../ZodForm";
import {ZodObject, ZodRawShape} from "zod";

type ZodFormContextType<T extends ZodRawShape> = {
    props: ZodFormProps<T>;
}

const ZodFormContext = createContext<ZodFormContextType<ZodRawShape> | undefined>(undefined);

export function useZodFormContext<T extends ZodRawShape>(){
  const context = useContext(ZodFormContext);
  if (context === undefined) {
    throw new Error('useZodFormContext must be used within a ZodFormProvider');
  }

  return context as unknown as ZodFormContextType<T>;
}

export function ZodFormProvider<A extends ZodRawShape>({ children, props }: { children: React.ReactNode, props: ZodFormProps<A>}) {

  return <ZodFormContext.Provider value={{
      props: props as any
  }}>
        {children}
  </ZodFormContext.Provider>
}

