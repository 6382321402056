import {TransactionStatus} from "shared/src/Entities/Transaction/transactionstatus.enum";
import {Chip} from "@mui/material";
import {Error, HelpOutline, Info, PlusOne} from "@mui/icons-material";
import React from "react";

export function TransactionStatusChip(props: {status: TransactionStatus}) {
    switch (props.status) {
        case TransactionStatus.CREATED.valueOf():
            return <Chip variant={"outlined"} icon={<PlusOne/>} label={"Aangemaakt"} color={"primary"}/>
            break;
        case TransactionStatus.FAILED.valueOf():
            return <Chip variant={"outlined"} icon={<Error/>} label={"Gefaald"} color={"error"}/>
            break;
        case TransactionStatus.PENDING.valueOf():
            return <Chip variant={"outlined"} icon={<HelpOutline/>} label="Bezig" color={"warning"}/>
            break;
        case TransactionStatus.SUCCESS.valueOf():
            return <Chip variant={"outlined"} icon={<Info/>} label={"Succesvol afgerond"} color={"success"}/>
            break
        default:
            return <Chip variant={"outlined"} icon={<Info/>} label={props.status} color={"info"}/>
    }
}
