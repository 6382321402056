import {Link, useParams} from "react-router-dom";
import useSWR from "swr";
import {GetTransactionDTO} from "shared/src/Entities/Transaction/GetTransactionDTO";
import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress, LinearProgress,
    Skeleton,
    Table,
    TableCell,
    TableRow
} from "@mui/material";
import React from "react";
import Container from "@mui/material/Container";
import {TransactionStatusChip} from "../Modules/Transaction/TransactionStatusChip";
import {Payment, Receipt} from "@mui/icons-material";

export const TransactionView: React.FC = () => {
    const {token} = useParams();

    const {
        data: transaction,
        error,
    } = useSWR<GetTransactionDTO>(token!=null?`/transactions/${token}`:null, {refreshInterval:10000});

    if (error) return <Alert severity={"error"}>Er is iets misgegaan, het kan zijn dat deze transactie niet bestaat of
        dat er een technische fout is opgetreden. </Alert>


    return <Container>
        <TransactionCard transaction={transaction}/>
    </Container>
}


export function TransactionCard({transaction}:{transaction?: GetTransactionDTO}) {

    return  <Card sx={{height:"100%"}}>
        <CardHeader title={<>Uw Transactieoverzicht</>} subheader={transaction?.Description}/>
        <CardContent>
            {transaction == null ? <Skeleton/> : <TransactionInformation transaction={transaction}/>}
        </CardContent>
        <CardActions>
            {transaction?.PaymentUrl&&<Button startIcon={<Payment/>} component={Link} to={transaction.PaymentUrl} variant={"contained"} sx={{marginRight:"1em"}}>Betalen</Button>}
            <Button startIcon={<Receipt/>} variant={"outlined"}>Factuur downloaden</Button>
        </CardActions>
    </Card>
}

function TransactionInformation(props: { transaction: GetTransactionDTO }) {
    return <Table>
        <TableRow>
            <TableCell>
                Status
            </TableCell>
            <TableCell>
                <TransactionStatusChip status={props.transaction.Status}/>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell>
                Totaalbedrag
            </TableCell>
            <TableCell>
                {props.transaction.Amount} {props.transaction.Currency}
            </TableCell>
        </TableRow>
    </Table>
}