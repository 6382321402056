import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Skeleton,
  Stack,
} from '@mui/material';
import {
  DetailedDataGrid,
  useSelectedDatagridId,
} from '../../../Components/TabbableDataGrid/DetailedDataGrid';
import { IFile } from 'shared/src/Entities/File/file.interface';
import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { usePaginatedSWR } from 'apps/client/src/hooks/pagination.hook';
import { SmartImage } from '../../../Components/Images/SmartImage';
import { FileDropZone } from '../../../Components/Form/FileDropzone';
import { useSnackbar } from 'apps/client/src/Components/Snackbar/snackbar.context.ts';
import { SharedConfig } from '../../../../../../shared/src/shared.config';
import { FileIcon } from 'apps/client/src/Components/FileIcon';
import { getMediaUrl } from '../../../Utils/getMediaUrl';

export function FileView() {
  const {
    swr: { isLoading, mutate, error },
    datagrid,
    items,
  } = usePaginatedSWR<IFile>('/files');
  const snackbar = useSnackbar();

  const columns: GridColDef[] = React.useMemo<GridColDef<IFile>[]>(
    () => [
      {
        field: 'thumb',
        headerName: 'Thumb',
        renderCell: ({ row }) =>
          SharedConfig.files.convertableMimes.includes(row.mime) ? (
            <SmartImage
              imgProps={{ style: { maxHeight: '100%' } }}
              src={row.id}
            />
          ) : (
            <FileIcon mime={row.mime} />
          ),
      },
      { field: 'name', flex: 1, headerName: 'Naam' },
      { field: 'mime', flex: 1, headerName: 'Type' },
      { field: 'id', flex: 1, headerName: 'Id' },
      { field: 'dateUploaded', flex: 1, headerName: 'Uploaddatum' },
    ],
    [items]
  );

  const handleUpload = async () => {
    mutate();
    snackbar('Bestand geupload');
  };

  if (isLoading) return <Skeleton sx={{ width: '100%' }} />;
  if (error || !items)
    return <Alert severity="error">Error loading files</Alert>;

  return (
    <Stack spacing={1}>
      <Card>
        <CardHeader title={'Uploaden'} />
        <CardContent>
          <FileDropZone onChange={(e) => handleUpload()} />
        </CardContent>
      </Card>
      <Box>
        <DetailedDataGrid
          dataGridWrapperComponent={Paper}
          gridColDef={columns}
          items={items}
          getRowId={(file: IFile) => file.id}
          datagridProps={{ ...datagrid, checkboxSelection: true }}
          detailComponent={<FileDetails />}
        />
      </Box>
    </Stack>
  );
}

function FileDetails() {
  const selectedId = useSelectedDatagridId<string>();
  const url = getMediaUrl(selectedId, 'original');
  return (
    <Card>
      <CardHeader title={selectedId} subheader={<a href={url}>{url}</a>} />
      <CardContent>
        <SmartImage variant={'original'} src={selectedId} />
      </CardContent>
    </Card>
  );
}
