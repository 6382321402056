import {Link, useParams} from "react-router-dom";
import useSWR from "swr";
import React from "react";
import {
    Alert,
    Card,
    CardActionArea,
    CardContent,
    Container,
    Grid,
    LinearProgress,
    MenuItem,
    Paper,
    Slide,
    Slider,
    Stack,
    Typography
} from "@mui/material";
import RolesOnly from "../Components/Restricted/RolesOnly";
import {AccountType} from "shared/src/Models/user.types";
import {ICategory} from "shared/src/Entities/Category/category.interface";
import {ProductFamilyType} from "shared/src/Entities/Product/ProductFamily/ProductFamily.interface";
import { ProductFamilyCard } from "../Modules/Products/ProductFamilyCard";


export default function Category() {
    const {category} = useParams();

    const {data: cat, isLoading, error} = useSWR<ICategory>(`/categories/${category}?withFamilies=true`);

    if (error) return <Alert severity={"error"}>Er is iets misgegaan met het ophalen van de producten.</Alert>
    if (cat == null || isLoading) return <LinearProgress/>;
    return <Grid container spacing={1}>
        <Grid item xs={2}>
            <Slide in={true} appear={true} direction={"right"}>
                <Paper>
                    <Container>
                        <Stack>
                            <Slider min={0} max={100}/>
                            <Slider min={0} max={100}/>
                            <Slider min={0} max={100}/>
                            <Slider min={0} max={100}/>
                            <Slider min={0} max={100}/>
                            <Slider min={0} max={100}/>
                            <Slider min={0} max={100}/>
                            <Slider min={0} max={100}/>
                        </Stack>
                    </Container>
                </Paper>
            </Slide>
        </Grid>
        <Grid item xs={10}>
            <Grid container spacing={1}>
                {
                    cat.ProductFamilies.map((p: number | ProductFamilyType) => <Grid item xs={12} sm={6} md={4} lg={3}
                                                                                     xl={2}>
                        <ProductFamilyCard productFamily={p as ProductFamilyType}/>
                    </Grid>)
                }
            </Grid>
        </Grid>
    </Grid>

}




function ProductContextMenu(props: { product: string, onUpdate: () => void }) {
    const [editing, setEditing] = React.useState(false);


    return <RolesOnly roles={[AccountType.Admin]}>
        {/*<UpsertProductDialog onClose={()=>{setEditing(false); props.onUpdate();}} open={editing} editingId={props.product}/>*/}
        <MenuItem onClick={() => setEditing(true)}>Bewerken</MenuItem>

    </RolesOnly>
}
