import {useEditor} from "../../hook/editor.context";
import {IconButton} from "@mui/material";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {useColorModule} from "./color-hook";
import {SwatchesPicker} from "react-color";
import {useState} from "react";

type fillStroke = "fill" | "stroke";
export function ColorSwitcher(){
    const editor = useEditor();
    const {swapColors} = useColorModule();
    const [selected, setSelected] = useState<fillStroke>("fill");

    return <div>
        <div className={"flex inline-flex"}>
        <div onClick={()=>setSelected("fill")} className="w-10 h-10 cursor-pointer" style={{backgroundColor: editor.settings.colors.fill, zIndex: selected=="fill"? 10 : "inherit" }}>

        </div>
        <IconButton onClick={swapColors} className={"relative"}><SwapHorizIcon/></IconButton>
        </div>
        <div onClick={()=>setSelected("stroke")} className="w-10 h-10 cursor-pointer" style={{border:`10px solid ${editor.settings.colors.stroke}`, position:"relative", top:"-1.25rem", left:"1.25rem", zIndex: selected=="stroke"? 10 : "inherit"}}>
        </div>

        <ColorPicker selected={selected}/>
    </div>
}


export function ColorPicker(props: {selected: fillStroke}){
const editor = useEditor();
    return <div>
       <SwatchesPicker onChangeComplete={(color)=>{
              editor.setSettings({...editor.settings, colors: {...editor.settings.colors, [props.selected]: color.hex}})
       }}/>
    </div>
}

