import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import useSWR from 'swr';
import { IPropertyFamily } from 'shared/src/Entities/Product/PropertyFamily/PropertyFamily.interface';
import { Add } from '@mui/icons-material';
import { UpsertPropertyFamilyDialog } from './UpsertPropertyFamily';
import { useState } from 'react';
import { ProductFamilyType } from 'shared/src/Entities/Product/ProductFamily/ProductFamily.interface';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import { UpsertPropertiesDialog } from './UpsertPropertiesStepper';
import { SmartImage } from '../../../../Components/Images/SmartImage';
type PropertiesProps = {
  familySlug: string;
};
export const Properties = (props: PropertiesProps) => {
  const { data, error, isLoading } = useSWR<ProductFamilyType>(
    `/v1/products/families/${props.familySlug}`,
    { revalidateOnMount: true }
  );
  const [adding, setAdding] = useState(false);

  const handleClose = () => {
    setAdding(false);
  };

  if (isLoading) return <Skeleton />;
  if (error || !data)
    return <Alert severity={'error'}>Er is iets misgegaan</Alert>;

  return (
    <Container>
      <UpsertPropertiesDialog
        open={adding}
        onClose={handleClose}
        familySlug={props.familySlug}
      />
      <Stack spacing={2}>
        <Button
          variant={'contained'}
          onClick={() => setAdding(true)}
          startIcon={<Add />}
        >
          Eigenschap toevoegen
        </Button>
        {data.PropertyFamilies?.map((property) => (
          <Property
            property={property}
            isVariation={property.Id == data.VariationProperty?.Id}
          />
        ))}
      </Stack>
    </Container>
  );
};

const Property = (props: {
  property: IPropertyFamily;
  isVariation: boolean;
}) => (
  <Card variant={'outlined'}>
    <CardHeader
      title={props.property.DisplayName}
      action={props.isVariation ? <BurstModeIcon /> : undefined}
    />
    <CardContent>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>Naam</TableCell>
            <TableCell>Waarde</TableCell>
            {props.property.Component == 'variation' && (
              <TableCell>Afbeelding</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.property.Properties.map((property) => (
            <TableRow key={property.Id}>
              <TableCell>{property.DisplayName}</TableCell>
              <TableCell>{property.Value}</TableCell>
              {props.property.Component == 'variation' && (
                <TableCell>
                  <SmartImage
                    src={property.VariationImage}
                    imgProps={{ style: { maxHeight: '3em' } }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CardContent>
  </Card>
);
