import {
  Alert,
  Box,
  CardMedia,
  InputProps,
  LinearProgress,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React, { DragEventHandler, useEffect, useRef, useState } from 'react';
import { useFileUpload } from '../../../hooks/upload.hook';
import { useApi } from '../../Request/useApi';
import useSWR from 'swr';
import { IFile } from 'shared/src/Entities/File/file.interface';
import { SmartImage } from '../../Images/SmartImage';
import Skeleton from '@mui/material/Skeleton';
import { FileDropZone } from '../FileDropzone';

type RHFDropzoneProps = {
  name: string;
  label?: string;
} & InputProps;

export function RHFDropzone({
  defaultValue,
  disabled,
  ...props
}: RHFDropzoneProps) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field: { ...other }, fieldState: { error } }) => {
        // input => onChange()
        return <FileDropZone label={props.label} {...other} />;
      }}
    />
  );
}
