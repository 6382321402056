import { useSelectedDatagridId } from '../../../../Components/TabbableDataGrid/DetailedDataGrid';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
} from '@mui/material';
import { ProductFamilyForm } from '../../ProductFamilyForm';
import { Properties } from '../Properties/Properties';
import { ProductFamilyProducts } from '../ProductFamilyProducts';
import { Tabbable } from '../../../../Components/Tabs/Tabbable';
import { useProductFamily } from '../../hooks/products.hook.ts';

export function ProductFamilyDetails() {
  const selectedSlug = useSelectedDatagridId<string>();
  const [refreshOverride, setRefreshOverride] = useState(false);

  const { productFamily, error, productFamilyLoading } = useProductFamily(
    selectedSlug,
    ['Properties', 'Products', 'Thumbnail']
  );

  useEffect(() => {
    if (refreshOverride) setRefreshOverride(false);
  }, [refreshOverride]);

  useEffect(() => {
    setRefreshOverride(true);
  }, [productFamily]);

  const tabs = useMemo(
    () => [
      {
        label: 'Algemeen',
        panel: (
          <Box p={1}>
            <ProductFamilyForm toEdit={productFamily} />
          </Box>
        ),
      },
      {
        label: 'Eigenschappen',
        panel: <Properties familySlug={selectedSlug} />,
      },
      {
        label: 'Producten',
        panel: <ProductFamilyProducts id={selectedSlug} />,
      },
      {
        label: 'Editor',
        panel: <></>,
      },
      {
        label: 'Media',
        panel: <></>,
      },
    ],
    [productFamily, refreshOverride]
  );

  if (error)
    return (
      <Alert severity={'error'}>Dit product kon niet opgehaald worden</Alert>
    );
  if (productFamily == null) return <Skeleton sx={{ height: '20em' }} />;
  return (
    <Card>
      <CardHeader title={productFamily.Name} />
      <CardContent>
        {refreshOverride ? (
          <Skeleton sx={{ height: '20em' }} />
        ) : (
          <Tabbable tabs={tabs} />
        )}
      </CardContent>
    </Card>
  );
}
