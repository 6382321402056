import {useEditor} from "../../hook/editor.context";
import {fabric} from "fabric";
import {useEffect} from "react";

export function useTextModule(){
    const {canvas, settings, setSettings} = useEditor();

    function addText(text: string){
        if(canvas == null) throw new Error("Canvas is null");
        const id = Math.random().toString(36).substring(7);
        console.log("Adding text", text);
        const text1 = new fabric.IText(text, {
            left: 0,
            top: 0,
            fontFamily: 'helvetica',
            angle: 0,
            fill: settings.colors.fill,
            stroke: settings.colors.stroke,
            scaleX: 1,
            scaleY: 1,
            fontWeight: '',
            hasRotatingPoint: true,
            textAlign:"left",
            name: `i-text-${id}`
        });

        canvas.add(text1);
    }

    return{
        addText
    }
}