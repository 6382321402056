import {EditorProvider} from "../Modules/Editor/hook/editor.context";
import {Canvas} from "../Modules/Editor/Canvas";
import {Sidebar} from "../Modules/Editor/toolbars/sidebar";
import {TextQuickBar} from "../Modules/Editor/modules/text/quickbar";
import React from "react";
import {Grid} from "@mui/material";
import {SAMDialog} from "../Modules/Editor/modules/SAM/SAMDialog";
import {WebsocketProvider} from "../Modules/Socket/Socket.provider.ts";
import { LiveSessionProvider } from "../Modules/Editor/modules/session/hooks/liveSessionContext";

export function Editor(){

    return     <WebsocketProvider>
                <EditorProvider>
                    <LiveSessionProvider>
                    {/*<SAMDialog/>*/}
                    <TextQuickBar/>
                    <Grid container>
                        <Grid item>
                            <Sidebar/>
                        </Grid>
                        <Grid item>
                            <Canvas/>
                        </Grid>
                    </Grid>
                    </LiveSessionProvider>
            </EditorProvider>
    </WebsocketProvider>
}