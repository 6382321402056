import { ZodFormFieldsType } from 'apps/client/src/Components/zForm/ZodForm';
import { ProductFamilyCreateType } from 'shared/src/Entities/Product/schema/ProductFamilyCreate.schema';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { FileDropZone } from '../../../Components/Form/FileDropzone';
import { useWebsocketEvent } from '../../Socket/Socket.provider.ts';
import { useState } from 'react';

const fieldConfiguration: ZodFormFieldsType<ProductFamilyCreateType> = {
  Id: {
    hidden: false,
  },
  CategoryIds: {
    hidden: false,
  },
  Price: {
    grid: { xs: 6, sm: 6 },
    hidden: false,
  },
  ThumbnailId: {
    grid: { xs: 6, sm: 6 },
    hidden: false,
  },
  PropertyFamilyIds: {
    hidden: false,
  },
  AttachmentIds: {
    hidden: false,
  },
};

export function TestView() {
  const [color, setColor] = useState<string>('rgb(0,0,0)');

  useWebsocketEvent('color', (data) => {
    setColor(`rgb(${data.r},${data.g},${data.b})`);
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card>
          <Box
            sx={{ width: '5em', height: '5em', backgroundColor: color }}
          ></Box>
          <CardContent>
            <FileDropZone
              defaultValue={'d5470301-a9c1-450b-81c1-c74dc7d9c64c'}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
