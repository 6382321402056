import {SharedConfig} from "shared/src/shared.config";
import axios from "axios";
import {useEffect, useState} from "react";


export function useAddressCompletion(postal: string, number: string){
    const [completion, setCompletion] = useState<AddressCompletion|undefined>(undefined)

    useEffect(() => {
        if (postal.length !== 6 || number.length === 0) {
            setCompletion(undefined);
            return;
        }
        axios.get(`${SharedConfig.pdok.url.replace("{postal}", postal).replace("{number}", number)}`).then((response) => {
            const data: AddressCompletionResponse = response.data;
            if(data.response.numFound > 0){
                setCompletion(data.response.docs[0]);
            }
        }).catch((e) => {
           setCompletion(undefined)
        });
    }, [postal, number]);

    return completion;
}

type AddressCompletionResponse = {
    response: {
        docs: AddressCompletion[]
        numFound: number
    }
}

type AddressCompletion = {
    woonplaatsnaam: string,
    provincienaam: string,
    huisnummer: string,
    straatnaam: string
}