import React, {createContext, useState} from "react";
import {Snackbar} from "@mui/material";

const SnackbarContext = createContext((message: string) => {
    console.log("not implemented")
});

export function SnackbarProvider(props: any) {
    const [snackbar, setSnackbar] = useState<string | null>("Deze website is nog onder ontwikkeling, voor de werkende versie ga naar https://jouwdesign.nu");
    const showSnackbar = (message: string) => {
        setSnackbar(message);
    }

    return <SnackbarContext.Provider value={showSnackbar}>
        {props.children}
        {snackbar &&
            <Snackbar open={true} autoHideDuration={6000} onClose={() => setSnackbar(null)} message={snackbar}/>}
    </SnackbarContext.Provider>
}


export function useSnackbar() {
    return React.useContext(SnackbarContext);
}