import React from 'react';
import {
  AppBar,
  Badge,
  Box,
  Collapse,
  CssBaseline,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  useTheme,
} from '@mui/material';

import { Link } from 'react-router-dom';
import { useMobile } from '../../hooks/mobile.hook.ts';
import {
  Facebook,
  Instagram,
  Search,
  ShoppingCart,
  WhatsApp,
} from '@mui/icons-material';
import AccountAvatar from '../../Modules/Account/Components/AccountAvatar';

export default function Header() {
  const theme = useTheme();
  const isMobile = useMobile();

  return (
    <CssBaseline>
      <AppBar
        position={'sticky'}
        sx={{
          position: 'sticky',
          top: 0,
          margin: 0,
          marginBottom: '0.5em',
          padding: '0.5em',
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Link to={'/'}>
            <img
              alt={'Het Jouwdesign logo'}
              src={
                isMobile
                  ? '/assets/img/wit/logo-wit.svg'
                  : '/assets/img/wit/header-text-web-wit.svg'
              }
              style={{ height: '3em', marginLeft: '1em' }}
            />
          </Link>
          <Box sx={{ flexGrow: '1' }}></Box>
          {!isMobile ? (
            <>
              <IconButton
                title={'whatsapp'}
                href={'https://api.whatsapp.com/message/HDR6NBU2YLM4N1'}
              >
                {' '}
                <WhatsApp sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title={'instagram'}
                href={'https://www.instagram.com/jouwdesign.nu/'}
              >
                {' '}
                <Instagram sx={{ color: 'white' }} />
              </IconButton>
              <IconButton
                title={'facebook'}
                href={'https://www.facebook.com/jouwdesign.nu'}
              >
                {' '}
                <Facebook sx={{ color: 'white' }} />
              </IconButton>
            </>
          ) : (
            ''
          )}
          <Stack>
            <OutlinedInput
              autoComplete={'off'}
              type={'text'}
              color={'primary'}
              size={'small'}
              sx={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
              endAdornment={
                <InputAdornment position={'end'}>
                  <IconButton>
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
            />
            <Collapse></Collapse>
          </Stack>

          <IconButton title={'winkelwagen'}>
            <Badge badgeContent={10000} color={'primary'}>
              <ShoppingCart sx={{ color: 'white' }} />
            </Badge>
          </IconButton>
          <AccountAvatar />
        </Stack>

        {/*<Divider sx={{color: "white"}} />*/}
      </AppBar>
    </CssBaseline>
  );
}
