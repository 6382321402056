import { jdenv } from 'apps/api/src/environments/environment';

const environment = jdenv.environment;

export const SharedConfig = {
  headers: {
    userToken: 'jd-usertoken',
  },
  public: {
    url: environment == 1 ? 'https://jouw.design' : 'http://localhost',
    websocket: environment == 1 ? 'https://jouw.design' : 'http://localhost',
  },
  api: {
    url: '/api',
  },
  security: {
    maxLoginAttempts: 3,
  },
  display: {
    imagesPerPage: 12,
  },
  hcaptcha: {
    sitekey: '232bf00e-2526-4a4a-95bb-920f73151b1c',
  },
  tkn: {
    broek_price: 20,
    jas_price: 20,
    polo_price: 20,
  },
  pdok: {
    url: 'https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?fq=postcode:{postal}&fq=huisnummer={number}',
  },
  files: {
    convertableMimes: ['image/jpeg', 'image/png', 'image/webp'],
    variants: {
      small: [128, 128],
      medium: [512, 512],
      large: [1080, 1080],
    },
  },
};
