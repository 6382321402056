import { Chip } from "@mui/material";
import {useLiveSession} from "./hooks/liveSessionContext";
import React from "react";

export function SessionStatusChip(){
    const session = useLiveSession();

    return <Chip color={session.id==null?"warning":"success"} label={session.id==null?"Geen sessie":`Sessie: ${session.id}`}/>


}