import {useEditor} from "../../hook/editor.context";
import {SharedConfig} from "shared/src/shared.config";
import React from "react";
import {Button} from "@mui/material";
import {useSnackbar} from "../../../../Components/Snackbar/snackbar.context.ts";
import {useLiveSession} from "./hooks/liveSessionContext";

export function InviteSessionButton(){
    const editor = useEditor();
    const snackbar = useSnackbar();
    const session = useLiveSession();

    return <Button onClick={async () => {
             let _sessionId = session.id;
            if (session.id == null){
                const ses = await session.startSession();
                _sessionId = ses.session_id;
            }
            navigator.clipboard.writeText(`${SharedConfig.public.url}/ontwerpen/1/${_sessionId}`);
            snackbar("Link gekopieerd naar klembord");

    }}>Invite</Button>
}