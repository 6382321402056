import { useEditor } from "../../hook/editor.context";
import {useEffect} from "react";
import {fabric} from "fabric";

export function useColorModule(){
    const editor = useEditor();

    function swapColors(){
        editor.setSettings({...editor.settings, colors: {...editor.settings.colors, fill: editor.settings.colors.stroke, stroke: editor.settings.colors.fill}})
    }

    function updateColors(objects: fabric.Object[]){
       objects.forEach(o => {
          editor.setSettings({...editor.settings, colors: {...editor.settings.colors, fill: o.fill as string, stroke: o.stroke as string}})
        });
    }
    useEffect(() => {
        editor.canvas?.getActiveObjects().forEach(o => {
            o.set({fill: editor.settings.colors.fill, stroke: editor.settings.colors.stroke})
        });
        editor.canvas?.requestRenderAll();
        console.log("updated colors")
    }, [editor.settings]);


    useEffect(() => {
        editor.canvas?.on("selection:created", (e) => {
            if (e.selected) updateColors(e.selected);
        });
        editor.canvas?.on("selection:updated", (e) => {
            if (e.selected) updateColors(e.selected);
        });

        return () => {
            editor.canvas?.off("selection:created");
            editor.canvas?.off("selection:updated");
        }
    }, [ editor.canvas]);



    return {
        swapColors
    }

}