import { ZodForm, ZodFormFieldsType } from '../../Components/zForm/ZodForm';
import {
  ProductFamilyCreateSchema,
  ProductFamilyCreateType,
} from 'shared/src/Entities/Product/schema/ProductFamilyCreate.schema';
import React, { useMemo } from 'react';
import { ProductFamilyType } from 'shared/src/Entities/Product/ProductFamily/ProductFamily.interface';
import { useDatagridUpdate } from '../../Components/TabbableDataGrid/DetailedDataGrid';
import { RHFFileUpload } from '../../Components/Form/RHF/RHFMedia';
import { Grid } from '@mui/material';
import { useApi } from '../../Components/Request/useApi';
import { RHFDropzone } from '../../Components/Form/RHF/RHFFileDropzone';

const fieldConfiguration: ZodFormFieldsType<ProductFamilyCreateType> = {
  Id: {
    hidden: true,
    readonly: true,
  },
  CategoryIds: {
    hidden: true,
  },
  Price: {
    hidden: false,
  },
  ThumbnailId: {
    hidden: false,
    component: (
      <Grid item xs={12}>
        <RHFDropzone label={'Thumbnail'} name={'ThumbnailId'} />
      </Grid>
    ),
  },
  PropertyFamilyIds: {
    hidden: true,
  },
  AttachmentIds: {
    hidden: true,
  },
};
type ProductFamilyFormProps = {
  toEdit?: ProductFamilyType | undefined;
};

export function ProductFamilyForm(props: ProductFamilyFormProps) {
  const api = useApi();
  const update = useDatagridUpdate();

  const handleSubmit = async (data: ProductFamilyCreateType) => {
    if (props.toEdit != undefined) {
      await api.put('/products/family', data);
    } else {
      await api.post('/products/family', data);
    }
  };

  const config = useMemo(
    () => ({
      ...fieldConfiguration,
      ...(props.toEdit != undefined
        ? {
            Id: {
              hidden: false,
              readonly: true,
            },
            Slug: {
              readonly: true,
            },
          }
        : {}),
    }),
    [props.toEdit]
  );

  const toEdit =
    props.toEdit != undefined
      ? ProductFamilyCreateSchema.parse({
          ThumbnailId:
            props.toEdit?.Thumbnail?.id ?? props.toEdit.Thumbnail ?? '',
          ...props.toEdit,
        })
      : undefined;
  return (
    <ZodForm
      schema={ProductFamilyCreateSchema}
      toEdit={toEdit}
      fields={config}
      onSubmit={handleSubmit}
    />
  );
}
