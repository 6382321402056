import { InsertDriveFile } from '@mui/icons-material';

type Props = {
  mime: string;
};
export function FileIcon({ mime }: Props) {
  switch (mime) {
    case 'application/pdf':
      return (
        <img
          style={{ maxHeight: '100%' }}
          src={'/assets/icons/pdf.svg'}
          alt="pdf"
        />
      );
    default:
      return <InsertDriveFile />;
  }
}
