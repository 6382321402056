import React, {useEffect, useMemo, useState} from "react";
import {useAccount} from "../Account/account.hook";
import {Box, FormControl, FormLabel, InputLabel, MenuItem, Select} from "@mui/material";
import {useOrganization} from "./OrganizationContext/organization.hook";
import useId from "@mui/material/utils/useId";
import {ICompanyEntity} from "shared/src/Entities/Company/company.entity.interface";

export function SelectOrganization() {
    const user = useAccount();
    const {selectedOrganizationId, setSelectedOrganizationId} = useOrganization();
    const id = useId();
    const availableOrganizations = useMemo(() => (user?.Memberships ?? []).map(membership => membership.Organization), [user?.Memberships]);

    if (!availableOrganizations || availableOrganizations.length == 0) return <Box></Box>; //hide

    return <FormControl>
        <InputLabel id={id} >Huidige organisatie</InputLabel>
        <Select labelId={id} label={"Huidige organisatie"} value={selectedOrganizationId}
                onChange={(e) => setSelectedOrganizationId(e.target.value as string)}>
            {availableOrganizations.map(organization => <MenuItem key={organization.Semantic}
                                                                  value={organization.Semantic}>{organization.Name}</MenuItem>)}
        </Select>
    </FormControl>
}