import { Canvas } from "fabric/fabric-impl";
import { IEventStream } from "./IEventStream";

export const objectModifiedEventStream: IEventStream = {
    handleIncoming(canvas: Canvas, data: any): void {
        const obj = canvas.getObjects().find(obj => obj.name == data.name);
        if (obj == null) return;
        for (const key in data.data){
            obj.set(key as any, data.data[key]);
        }

        canvas.renderAll();
    }, registerSender(data: any): void {
        throw new Error("Method not implemented.");
    }

}