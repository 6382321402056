import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useId, useState } from 'react';
import { RHFTextfield } from '../../../../Components/Form/RHF/RHFTextfield';
import { FormProvider, useForm } from 'react-hook-form';
import { FileDropZone } from '../../../../Components/Form/FileDropzone';
import { Add } from '@mui/icons-material';
import { SmartImage } from '../../../../Components/Images/SmartImage';
import { toSlug } from '../../../../../../../shared/src/Utility/toSlug';
import { useApi } from '../../../../Components/Request/useApi';

export interface IUpsertPropertyFamilyDialog {
  open: boolean;
  onClose: () => void;
  editingId?: number | null;
  familySlug: string;
}

export function UpsertPropertiesDialog(props: IUpsertPropertyFamilyDialog) {
  return (
    <Dialog fullWidth={true} open={props.open} onClose={props.onClose}>
      <DialogTitle>Nieuwe eigenschap toevoegen</DialogTitle>
      <DialogContent>
        <UpsertPropertiesStepper familySlug={props.familySlug} />
      </DialogContent>
    </Dialog>
  );
}

type UpsertPropertiesStepperProps = {
  familySlug: string;
};

export function UpsertPropertiesStepper(props: UpsertPropertiesStepperProps) {
  const [activeStep, setActiveStep] = useState(0);

  const methods = useForm();
  const propertyLabelId = useId();
  const [type, setType] = useState<string>('dropdown');
  const [properties, setProperties] = useState<AddedProperty[]>([]);
  const api = useApi();

  const handleSelectType = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const steps = [
    {
      label: 'Eigenschappen',
      description: (
        <Stack spacing={2}>
          <RHFTextfield name={'name'} label={'Naam'} />
          <FormControl fullWidth>
            <InputLabel id={propertyLabelId}>Weergave</InputLabel>
            <Select
              value={type}
              onChange={handleSelectType}
              labelId={propertyLabelId}
              label="Weergave"
            >
              <MenuItem value={'dropdown'}>Dropdown</MenuItem>
              <MenuItem value={'variation'}>Variatie (afbeelding)</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: 'Waardes',
      description: <AddProperty onChange={setProperties} type={type} />,
    },
  ];

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      api.post('products/properties/families', {
        DisplayName: methods.getValues('name'),
        Component: type,
        ProductFamily: props.familySlug,
        Properties: properties.map((property) => ({
          Name: property.name,
          Value: property.value,
          Image: property.image,
        })),
      });
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <FormProvider {...methods}>
      <Stepper activeStep={activeStep} orientation={'vertical'}>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </FormProvider>
  );
}

type AddPropertyProps = {
  type: string;
  onChange?: (properties: AddedProperty[]) => void;
};
type AddedProperty = {
  name: string;
  value: string;
  image?: string;
};

function AddProperty({ type, onChange }: AddPropertyProps) {
  const [properties, setProperties] = useState<AddedProperty[]>([]);
  const [currentProperty, setCurrentProperty] = useState<AddedProperty>({
    name: '',
    value: '',
    image: '',
  });

  useEffect(() => {
    onChange?.(properties);
  }, [properties]);

  useEffect(() => {
    if (currentProperty.value == toSlug(currentProperty.name)) return;

    setCurrentProperty({
      ...currentProperty,
      value: toSlug(currentProperty.name),
    });
  }, [currentProperty.name]);
  const handleAddProperty = () => {
    if (!currentProperty) return;
    setProperties((props) => [...props, currentProperty]);
  };

  return (
    <div>
      <Stack spacing={2}>
        <TextField
          label={'Naam'}
          onChange={(e) =>
            setCurrentProperty({ ...currentProperty, name: e.target.value })
          }
        />
        <TextField
          label={'Waarde'}
          value={currentProperty.value}
          onChange={(e) =>
            setCurrentProperty({ ...currentProperty, value: e.target.value })
          }
        />
        {type == 'variation' && (
          <FileDropZone
            label={'Afbeelding'}
            onChange={(e) =>
              setCurrentProperty({ ...currentProperty, image: e.target.value })
            }
          />
        )}
        <Button startIcon={<Add />} onClick={handleAddProperty}>
          Toevoegen
        </Button>

        <Divider />
        <Table size={'small'}>
          <TableHead>
            <TableCell>
              <Typography>Naam</Typography>
            </TableCell>
            <TableCell>
              <Typography>Waarde</Typography>
            </TableCell>
            {type == 'variation' && (
              <TableCell>
                <Typography>Afbeelding</Typography>
              </TableCell>
            )}
          </TableHead>
          <TableBody>
            {properties.map((property, index) => (
              <TableRow key={index}>
                <TableCell>{property.name}</TableCell>
                <TableCell>{property.value}</TableCell>
                {type == 'variation' && (
                  <TableCell>
                    <Box sx={{ maxHeight: '3em' }}>
                      <SmartImage
                        src={property.image}
                        imgProps={{ style: { maxHeight: '3em' } }}
                      />
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
    </div>
  );
}
