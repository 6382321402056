import {useEditor} from "../../../hook/editor.context";
import {useWebsocket, useWebsocketEvent} from "../../../../Socket/Socket.provider.ts";
import { useAccount } from "apps/client/src/Modules/Account/account.hook";
import {IBareUserData} from "shared/src/Entities/Editor/editorSession";
import {useEffect} from "react";
import {objectModifiedEventStream} from "../eventStreams/object_modified";

type ICanvasUpdate = {
    user: IBareUserData,
    data: any,
    event: string
}

const events = [];

export function useCanvasSocketHandler(){
    const editor = useEditor();
    const user = useAccount();
    const socket = useWebsocket();


    useWebsocketEvent("session_canvas_update", (data) => {
        console.log("Canvas update", data);
        if (data.user.Id==user?.Id) return;
        const canvas = editor.canvas;
        if (canvas == null) throw new Error("Canvas is null");

        switch (data.event){
            case "object:modified": case "object:moving": objectModifiedEventStream.handleIncoming(canvas, data.data); break;
            case "object:added": canvas.add(data.data); break;
            default: console.log("Unhandled event", data.event); break;
        }
    });

    function emit(event: string, data: any){
        socket?.emit("session_canvas_update", {data, event});
    }

    function onCanvasEvent(eventName: string, callback: (data: any) => void){
        events.push({eventName, callback});
        const _canvas = editor.canvas;
        if (_canvas == null) throw new Error("Canvas is null");

        _canvas.on(eventName, (e)=>{
            callback(e);
            console.log("Event", e, eventName);
        });
    }



    useEffect(() => {
        if (editor.canvas == null) return;
        onCanvasEvent("object:modified", (e) => emit("object:modified", {name: e.target?.name, data: e.target}));
        onCanvasEvent("object:moving", (e) => emit("object:moving", {name: e.target?.name, data: {
            left: e.target?.left,
            top: e.target?.top
            }}));
        //onCanvasEvent("object:added", (e) => emit("object:added", {name: e.target?.name, data: e.target}));


    }, [editor.canvas]);



}