import React, {useMemo} from "react";
import {Button as MuiButton, ButtonProps} from "@mui/base";
import { Link } from "react-router-dom";


type JDButtonProps = {
    variant?: "outlined" | "text" | "contained";
    isLink?: boolean;
    to?: string;
} & ButtonProps

export const Button = React.forwardRef<HTMLButtonElement, JDButtonProps>((props, ref)=>{

    const classes = useMemo(()=>{
        switch (props.variant) {
            case "outlined":
                return "inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-700 border-primary-700 border-2 rounded  hover:bg-gray-50 focus:ring-1 focus-pulse focus:ring-primary-300 dark:focus:ring-primary-900";
                break;
            case "text":
                return "rounded inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-700";
                break;
            case "contained":
                return "rounded inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white border-primary-700 border-2 bg-primary-700 hover:bg-primary-800 hover:shadow focus:ring-1 focus:ring-primary-300 dark:focus:ring-primary-900";
                break;
            default:
                return "inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-700 border-primary-700 border-2 rounded  hover:bg-gray-50 focus:ring-1 focus-pulse focus:ring-primary-300 dark:focus:ring-primary-900";
                break;
        }
    },[props.variant])

    if (props.to) return <Link to={props.to} className={classes}>
        {props.children}
    </Link>

    return <MuiButton {...props} className={classes}>
        {props.children}
    </MuiButton>
});

