import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    LinearProgress, MenuItem, MenuList,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import PrinterCalculator from "../Components/PrinterCalculator";
import {Calculate, Download, ErrorRounded, FileDownload} from "@mui/icons-material";

import {ProblematicItems} from "../Components/ProblematicItems";
import {useMobile} from "../../../hooks/mobile.hook.ts";
import {useWebsocketEvent} from "../../Socket/Socket.provider.ts";
import {useSnackbar} from "../../../Components/Snackbar/snackbar.context.ts";
import {LoadingButton} from "@mui/lab";
import useSWR from "swr";
import {IUploadEntity} from "shared/src/Entities/Upload/Upload.interface";
import AutoScaledImage from "../../../Components/Images/AutoScaledImage";
import axios from "axios";


export default function FileUpload() {
    const mobile = useMobile();
    const snackbar = useSnackbar();
    const [loading, setLoading] = useState(false);
    const {data, mutate} = useSWR<IUploadEntity[]>("/upload/links");
    const [selectedToken, setSelectedToken] = useState<string | null>(null);

    const copyLink = () => {
        setLoading(true);
        fetch("/api/upload/link", {method: "POST"}).then(res => res.text()).then(res => {
            setLoading(false);
            navigator.clipboard.writeText(`https://${window.location.host}/upload/${res}`);
            snackbar("Link gekopieerd naar klembord");
            setSelectedToken(res);
        }).catch(err => {
            snackbar("Er is iets misgegaan");
        }).finally(() => {
            setLoading(false);
            mutate();
        });

    }

    return <Container disableGutters={true}>
        <Grid container spacing={1}>
            <Grid item xs={3}>
                <Stack direction={"row"}>
                    <LoadingButton loading={loading} onClick={copyLink} variant={"contained"} color={"primary"}>Nieuwe
                        link aanmaken</LoadingButton>
                </Stack>
                <MenuList>
                    {data?.map((item, index) => <MenuItem onClick={() => setSelectedToken(item.Token)}
                                                          key={item.Token}>item</MenuItem>)}
                </MenuList>
            </Grid>
            <Grid item xs={9}>
                {
                    selectedToken == null ? <></> : <Uploaded token={selectedToken}/>
                }

            </Grid>

        </Grid>
    </Container>
}


function Uploaded({token}: { token?: string }) {
    const {data, error} = useSWR<IUploadEntity>(`/upload/link/${token}`, {refreshInterval: 1000});
    const [signedUrl, setSignedUrl] = useState<string | null>(null);

    useEffect(() => {
        axios.get(`/api/files/signed/${token?.toLowerCase()}`).then(res => {
            setSignedUrl(res.data);
        });

    }, [token]);


    if (error) return <Alert severity={"error"}>Er is iets misgegaan</Alert>
    return <Container component={Paper} sx={{padding:"1em"}}>
        {data == null ? <LinearProgress/> : <>
                {!data?.Completed?  <Alert severity={"info"}>Er is nog geen bestand geupload</Alert> :
                    //display file with tailwind with download button
                    <div>
                            <FileDownload/>
                            <Typography variant={"body1"}>{data?.FileName}</Typography>
                        {data.MimeType?.startsWith("image/")&&<img src={signedUrl??""}/>}
                        {signedUrl&& <a  href={signedUrl} download>Download</a>}


                    </div>
                }
        </>}
    </Container>
}
