import {Alert, Button, IconButton, Paper} from "@mui/material";
import useSWR from "swr";
import React from "react";
import {GridColDef} from "@mui/x-data-grid";
import {Download, Refresh} from "@mui/icons-material";
import {TransactionStatusChip} from "../../Transaction/TransactionStatusChip";
import {usePaginatedSWR} from "../../../hooks/pagination.hook";
import {TransactionCard} from "../../../Views/Transaction";
import Skeleton from "@mui/material/Skeleton";
import {GetTransactionDTO} from "shared/src/Entities/Transaction/GetTransactionDTO";
import {useSnackbar} from "../../../Components/Snackbar/snackbar.context.ts";
import {DetailedDataGrid, useSelectedDatagridId} from "../../../Components/TabbableDataGrid/DetailedDataGrid";
import { useApi } from "apps/client/src/Components/Request/useApi";

//
// type GetTransactionDTO = Omit<ITransaction, 'MollieId' | 'MolliePaymentUrl'>;

export default function TransactionTable() {
    const [page, setPage] = React.useState(0);
    const api = useApi();
    const snackbar = useSnackbar();
    const {
        datagrid,
        swr: {
            mutate
        },
        items: transactions
    } = usePaginatedSWR<GetTransactionDTO>("/transactions");


    function updatePendingTransactions() {
        api.post("/api/transactions/update/pending").then(() => {
            mutate();
            snackbar("Transacties zijn bijgewerkt")
        })
    }

    const columns: GridColDef[] = React.useMemo<GridColDef<GetTransactionDTO>[]>(() => [
        {field: 'Id', flex: 1, headerName: 'ID'},
        {
            field: "Status", flex: 1, headerName: "Status", renderCell: ({row}) => {
                return <TransactionStatusChip status={row.Status}/>
            }
        },
        {
            field: 'Description',
            headerName: "Beschrijving",
            flex: 1,
        },
        {
            field: 'Amount',
            headerName: "Amount"
        },
        {
            field: 'CreatedAt',
            headerName: "Created At",
            flex: 1,
        },
        {
            field: 'UpdatedAt',
            headerName: "Updated At",
            flex: 1,
        },
    ], [history]);

    if (!transactions) {
        return <div>Loading...</div>;
    }
    return <DetailedDataGrid<GetTransactionDTO>
            dataGridWrapperComponent={Paper}
            gridActions={[
            <IconButton onClick={() => mutate()}><Refresh/></IconButton>,
            <Button onClick={updatePendingTransactions} startIcon={<Download/>}>Force update</Button>,
        ]} gridColDef={columns} items={transactions} getRowId={(row: GetTransactionDTO) => row.Id}
                                             datagridProps={datagrid} detailComponent={<TransactionDetails/>}/>

}


function TransactionDetails() {
    const id = useSelectedDatagridId<string>();
    const {data, error, isLoading} = useSWR<GetTransactionDTO>(`/transactions/id/${id}`);
    if (isLoading) return <Skeleton/>;
    if (data == null || error) return <Alert severity={"error"}>Er is iets misgegaan</Alert>;

    return <TransactionCard transaction={data}/>
}

