import {Button, Card, CardActions, CardContent, CardHeader} from "@mui/material";
import { AccountType } from "shared/src/Models/user.types";
import useSWR from "swr";
import PrinterCalculator from "./PrinterCalculator";
import {Delete, Save} from "@mui/icons-material";
import {useSnackbar} from "../../../Components/Snackbar/snackbar.context.ts";
import {useApi} from "../../../Components/Request/useApi";

export function CacheStatusCard(){
    const api = useApi();
    const snackbar = useSnackbar();
    const cachedUsers = useSWR<{lastFetch: Date, username: string, accountType: AccountType, creation: Date}[]>("/users/active")
    const clearCache = ()=>api.post("/users/clear-cache").then(()=> {
        cachedUsers.mutate()
        snackbar("Cache cleared")
    });
    return <Card>
        <Card>
            <CardHeader title={"Gebruikerscache:"} avatar={<Save/>}/>
            <CardContent>
                <p>Cached users: {cachedUsers.data?.length}</p>
            </CardContent>
            <CardActions>
                <Button onClick={clearCache} startIcon={<Delete/>}>Cache geforceerd legen</Button>
            </CardActions>
        </Card>

    </Card>
}