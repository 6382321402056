import { IFile } from 'shared/src/Entities/File/file.interface';

export function getMediaUrl(file?: string | IFile, variant?: string): string {
  if (!file) return '';
  const id = typeof file === 'string' ? file : file.id;

  if (!variant || variant == 'original') return `/api/media/${id}`;

  return `/api/media/${id}/${variant}`;
}
