import React, { useMemo, useState } from 'react';
import {
  createTheme,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Snackbar,
  ThemeProvider,
} from '@mui/material';
//import { ThemeProvider } from '@jouwdesign/minimals';
import Config from './Config';
import Header from './Components/Header/Header';
import { Outlet, useLocation } from 'react-router-dom';
import SpeedDialContext from './Contexts/SpeedDialContext';
import { Help, Search } from '@mui/icons-material';
import { LoggingBoundary } from './Utils/ErrorBoundaries/LoggingBoundary';
import { SnackbarProvider } from './Components/Snackbar/snackbar.context.ts';
import { OrganizationProvider } from './Modules/Organizations/OrganizationContext/organization.context';
import { DialogContextProvider } from './Components/Dialog/DialogContext';
import { ApiProvider } from './Components/Request/ApiContext';

export default function Wrapper() {
  const theme = createTheme(Config.theme);
  const location = useLocation();
  const [speedDialComponents, setSpeedDialComponents] = useState<JSX.Element[]>(
    []
  );
  useMemo(() => {
    setSpeedDialComponents([]);
  }, [location]);
  const [showOfflineToast, setShowOfflineToast] = useState(false);

  window.addEventListener('offline', () => {
    setShowOfflineToast(true);
  });

  window.addEventListener('online', () => {
    setShowOfflineToast(false);
  });
  return (
    <>
      <Snackbar
        open={showOfflineToast}
        autoHideDuration={6000}
        onClose={() => setShowOfflineToast(false)}
        message="U bent offline, sommige onderdelen van de website zullen niet correct werken."
      />
      {/*<ContextMenuWrapper menuItems={<BaseContextMenu/>}>*/}
      <SnackbarProvider>
        <OrganizationProvider>
          <DialogContextProvider>
            <ApiProvider>
              <SpeedDialContext.Provider
                value={{
                  SetComponents: setSpeedDialComponents,
                  Components: speedDialComponents,
                }}
              >
                <ThemeProvider theme={theme}>
                  <Header />
                  <LoggingBoundary>
                    <Outlet />
                  </LoggingBoundary>
                </ThemeProvider>
              </SpeedDialContext.Provider>
            </ApiProvider>
          </DialogContextProvider>
        </OrganizationProvider>
      </SnackbarProvider>
      {/*</ContextMenuWrapper>*/}
    </>
  );
}

export function useSpeedDial(components: JSX.Element[]) {
  const context = React.useContext(SpeedDialContext);
  useMemo(() => {
    if (context !== null) {
      context.SetComponents(components);
    }
  }, []);
}

function BaseContextMenu() {
  return (
    <>
      <Divider />
      <MenuItem>
        <ListItemIcon>
          <Search />
        </ListItemIcon>
        <ListItemText>Zoeken</ListItemText>
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <Help />
        </ListItemIcon>
        <ListItemText>Hulp vragen</ListItemText>
      </MenuItem>
    </>
  );
}
