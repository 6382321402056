import { useTextModule } from "../modules/text/text-hook";
import {ColorSwitcher} from "../modules/color/ColorSwitcher";
import React from "react";
import { InviteSessionButton } from "../modules/session/InviteSessionButton";
import {SessionUserList} from "../modules/session/SessionUserList";
import {SessionStatusChip} from "../modules/session/SessionStatus";
import {Paper} from "@mui/material";

export function Sidebar(){
    const textModule = useTextModule();

    return <div>
        <button onClick={() => textModule.addText("Hello World")}>Add Text</button>
        <ColorSwitcher/>

        <Paper>
            <SessionStatusChip/>
            <InviteSessionButton/>
            <SessionUserList/>
        </Paper>



    </div>






}