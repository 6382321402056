import {
    Alert,
    Checkbox,
    Collapse,
    FormControlLabel,
    LinearProgress,
    Link,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import React, {ChangeEvent, useState} from "react";
import UserController from "../../Controllers/UserController";
import Errors from "../Errors/DisplayErrors";
import {validateOrReject, ValidationArguments} from "class-validator";
import {useNavigate} from "react-router-dom";
import {PasswordField} from "../Form/PasswordField";
import axios from "axios";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {useAccountActions} from "../../Modules/Account/account.hook";
import {useSnackbar} from "../Snackbar/snackbar.context.ts";
import {UserLoginDto, UserRegisterDto} from "shared/src/Models/user.types";
import {SharedConfig} from "shared/src/shared.config";
import { Button } from "../Buttons/Button";

interface ILoginRegister {
    Email: string,
    Password: string,

    ConfirmPassword?: string
}

interface IValidationErrors {
    Email?: string | null,
    Password?: string | null,
    ConfirmPassword?: string | null,
}

export function LoginRegisterFields() {
    const [isRegistering, setIsRegistering] = useState(false);
    const [errors, setErrors] = useState<string | null>(null);
    const [captchaCompleted, setCaptchaCompleted] = useState(false);
    const [validationErrors, setValidationErrors] = useState<IValidationErrors>({});
    // const [emailError, setEmailError] = useState<string|null>(null);
    const snackbar = useSnackbar();
    const [registerCompanyAccount, setRegisterCompanyAccount] = useState(false);
    const [loading, setLoading] = useState(false);
    const AccountActions = useAccountActions();

    const navigate = useNavigate();
    const [LoginRegisterObj, setLoginRegisterObj] = useState<ILoginRegister>({
        ConfirmPassword: "",
        Email: "",
        Password: ""
    })

    function resetErrors() {
        setValidationErrors({});
        setErrors(null);
    }

    function handleRegisterClick() {
        if (!isRegistering) {
            setIsRegistering(true);
            return
        }
        setLoading(true);
        const RegisterDTO = new UserRegisterDto();
        RegisterDTO.Email = LoginRegisterObj.Email;
        RegisterDTO.Password = LoginRegisterObj.Password;
        RegisterDTO.ConfirmPassword = LoginRegisterObj.ConfirmPassword ?? "";
        RegisterDTO.Firstname = "CHANGEME";
        RegisterDTO.Lastname = "CHANGEME";

        validateOrReject(RegisterDTO).then(() => {
            UserController.Register(RegisterDTO).then((user) => {
                console.log("user")
                setLoading(false);
                AccountActions.Fetch().then(() => {
                    setLoading(false)
                    if (registerCompanyAccount) {
                        navigate("/zakelijk/aanmelden")
                    }
                });

            }).catch((e) => {
                setLoading(false);
                switch (e.response.status) {
                    case 409:
                        setErrors("Dit emailadres is al in gebruik");
                        break;
                    default:
                        setErrors("Er is een onbekende fout opgetreden, probeer het later opnieuw.")
                }
            })
        }).catch((e) => {
            setLoading(false);
            console.log(e);
            if (e.some((item: ValidationArguments) => item.property === "ConfirmPassword")) {
                setValidationErrors({...validationErrors, ConfirmPassword: "Wachtwoorden komen niet overeen"})
            }
            if (e.some((item: ValidationArguments) => item.property === "Password")) {
                setValidationErrors({...validationErrors, Password: "Wachtwoord is niet sterk genoeg"})
            }
            if (e.some((item: ValidationArguments) => item.property === "Email")) {
                setValidationErrors({...validationErrors, Email: "Dit moet een emailadres zijn."})
            }
        })
    }

    function handleLoginClick() {
        if (isRegistering) {
            setIsRegistering(false);
            return
        }
        setLoading(true);
        const LoginDTO = new UserLoginDto();
        LoginDTO.Email = LoginRegisterObj.Email;
        LoginDTO.Password = LoginRegisterObj.Password;

        validateOrReject(LoginDTO).then(() => {
            UserController.Login(LoginDTO)
                .then(() => {
                    AccountActions.Fetch().then(() => {
                        setLoading(false);
                        snackbar("Welkom terug!")
                    })
                })
                .catch((e) => {
                    setLoading(false);
                    console.log(e);
                    switch (e.response.status) {
                        case 429:
                            setErrors(e.response.data.message);
                            break;
                        case 401:
                            setErrors("Het wachtwoord is onjuist of de ingevoerde gebruiker bestaat niet");
                            break;
                        default:
                            setErrors("Er is iets misgegaan, probeer het later opnieuw of neem contact op ons.")
                    }
                })
        }).catch((validationErrors) => {
            setLoading(false);
            console.log(validationErrors);
            if (validationErrors.some((item: ValidationArguments) => item.property === "Email")) {
                setValidationErrors({...validationErrors, Email: "Dit moet een emailadres zijn."})
            }
        })
    }

    function handleEmailChange(e: ChangeEvent<HTMLInputElement>) {
        setLoginRegisterObj({...LoginRegisterObj, Email: e.target.value.trim()})
        setValidationErrors({});
    }

    function handlePasswordChange(e: ChangeEvent<HTMLInputElement>) {
        setLoginRegisterObj({...LoginRegisterObj, Password: e.target.value.trim()})
        setValidationErrors({...validationErrors, ConfirmPassword: null})
    }

    function handleConfirmPasswordChange(e: ChangeEvent<HTMLInputElement>) {
        setLoginRegisterObj({...LoginRegisterObj, ConfirmPassword: e.target.value.trim()})
    }

    return <>
        <Stack spacing={1}>
            <Errors errors={errors}></Errors>
            <Alert severity={"info"}>Met een account kunt u uw ontwerpen opslaaan en uw vorige bestellingen
                inzien</Alert>
            {loading ? <LinearProgress sx={{margin: "1em"}}/> : ""}
            <TextField error={validationErrors.Email != null} disabled={loading} helperText={validationErrors.Email}
                       label={"Email"}
                       onChange={handleEmailChange} sx={{width: "100%"}} required variant={"outlined"}/>
            <PasswordField label={"Wachtwoord"} disabled={loading} error={validationErrors.Password != null}
                           helperText={validationErrors.Password} onChange={handlePasswordChange} type={"password"}
                           sx={{width: "100%"}} required variant={"outlined"}/>

            <Collapse in={isRegistering}>
                <Stack>

                    <PasswordField label={"Herhaal wachtwoord"} disabled={loading}
                                   error={validationErrors.ConfirmPassword != null}
                                   helperText={validationErrors.ConfirmPassword} type={"password"}
                                   onChange={handleConfirmPasswordChange} sx={{width: "100%"}} required/>
                    <Tooltip
                        title={"Met een bedrijfaccount kunt u ontwerpen bijhouden voor een bedrijf, ook kunnen wij speciale afspraken en prijzen hier vastleggen. Daarnaast kan het een plek bieden voor uw werknemers om hun werkkleding te bestellen of bestellingen voor te melden."}><FormControlLabel
                        onChange={(e, val) => {
                            setRegisterCompanyAccount(val)
                        }} control={<Switch/>} disabled={loading}
                        label={<Typography>Ik ben een zakelijke klant en wil ook een bedrijfsaccount
                            aanmaken</Typography>}/></Tooltip>
                    <HCaptcha languageOverride={"nl"} sitekey={SharedConfig.hcaptcha.sitekey}
                              onVerify={() => setCaptchaCompleted(true)}/>
                    <FormControlLabel control={<Checkbox/>} disabled={loading}
                                      label={<Typography>Ik ga akkoord met de <Link>Algemene
                                          voorwaarden</Link></Typography>}/>
                </Stack>
            </Collapse>
            <Stack direction={"row"} spacing={1}>
                <Collapse orientation={"horizontal"} in={!isRegistering}>
                    <Button variant={"contained"} onClick={handleLoginClick} disabled={loading}> Inloggen</Button>
                </Collapse>
                <Button onClick={handleRegisterClick} disabled={loading || (isRegistering && !captchaCompleted)}
                        variant={isRegistering ? "contained" : "text"}>Registreren</Button>
                <Collapse orientation={"horizontal"} in={isRegistering}>
                    <Button onClick={handleLoginClick} variant={!isRegistering ? "contained" : "text"} disabled={loading}> Inloggen</Button>
                </Collapse>
            </Stack>
            <Collapse in={!isRegistering}>
                <ForgotPassword LoginRegisterObj={LoginRegisterObj}
                                setLoginRegisterObj={setLoginRegisterObj}></ForgotPassword>
            </Collapse>


        </Stack>

    </>
}


function ForgotPassword({LoginRegisterObj}: any) {
    const [resetPassword, setResetPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    function makeResetPasswordRequest() {
        setLoading(true);
        axios.post(`/api/users/password-reset/${LoginRegisterObj.Email}`).then(() => {
            setResetPassword(true);
            setLoading(false)
        }).catch((e) => {
            setLoading(false);
        });
    }

    return loading ? <LinearProgress/> : resetPassword ?
        <Alert severity={"info"}>Er is een email verzonden naar {LoginRegisterObj.Email} met instructies om een nieuw
            wachtwoord in te stellen.</Alert> : <Link onClick={makeResetPasswordRequest}>Wachtwoord vergeten?</Link>
}