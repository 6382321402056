import React from 'react';
import Wrapper from './Wrapper';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Alert } from '@mui/material';
import { LinearSuspense } from './Components/Suspenses/LinearSuspense';
import Shop from './Views/Shop';
import Category from './Views/Categorie';
import { FileUploadClient } from './Views/Upload';
import AllProducts from './Views/Product/AllProducts';
import { Editor } from './Views/Editor';
import { adminRoutes } from './Modules/Admin/AdminRoutes';
import { TransactionView } from './Views/Transaction';
import { OrganizationIndex } from './Modules/Organization/Views/OrganizationIndex';
import { OrganizationBackoffice } from './Modules/Organization/Views/OrganizationBackoffice';
import { OrganizationWrapper } from './Modules/Organization/OrganizationWrapper';
// import { EditorOld } from "./Views/Editor-old";

const AdminWrapper = React.lazy(
  () => import('./Modules/Admin/views/AdminWrapper')
);
const Product = React.lazy(() => import('./Views/Product/Product'));
const TKNDashboard = React.lazy(() => import('./Modules/TKN/Dashboard'));
const ConfirmOrder = React.lazy(
  () => import('./Modules/Company/Views/ConfirmOrder')
);
const ViewOrders = React.lazy(
  () => import('./Modules/Company/Views/ViewOrders')
);
const Form = React.lazy(() => import('./Modules/Company/Views/Form'));
const Home = React.lazy(() => import('./Views/Home'));
const ResetPasswordView = React.lazy(() => import('./Views/ResetPassword'));
const InboxView = React.lazy(() => import('./Modules/Inbox/Inbox'));
const RegisterCompanyView = React.lazy(
  () => import('./Modules/Company/Views/RegisterCompany')
);

export default function JDRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/c'} element={<Wrapper />}>
          <Route path={'tkn'}>
            <Route
              index
              element={
                <LinearSuspense>
                  <TKNDashboard />
                </LinearSuspense>
              }
            ></Route>
            <Route
              path={'confirm/:token'}
              element={
                <LinearSuspense>
                  <ConfirmOrder />
                </LinearSuspense>
              }
            ></Route>
            <Route
              path={'view'}
              element={
                <LinearSuspense>
                  <ViewOrders />
                </LinearSuspense>
              }
            ></Route>
            <Route
              path={'trainingspakken'}
              element={
                <LinearSuspense>
                  <Form />
                </LinearSuspense>
              }
            ></Route>
          </Route>
          <Route path={':orgId'} element={<OrganizationWrapper />}>
            <Route index element={<OrganizationIndex />} />
            <Route path={'backoffice'} element={<OrganizationBackoffice />} />
          </Route>

          <Route
            path={'*'}
            element={
              <Alert severity={'error'}>
                Deze pagina kon niet worden gevonden.
              </Alert>
            }
          />
        </Route>

        <Route path="/" element={<Wrapper />}>
          <Route
            path={'upload/:token'}
            element={
              <LinearSuspense>
                <FileUploadClient />
              </LinearSuspense>
            }
          ></Route>
          <Route
            path={'reset-wachtwoord/:token'}
            element={
              <LinearSuspense>
                <ResetPasswordView />
              </LinearSuspense>
            }
          />
          <Route path={'ontwerpen/:id'} element={<Editor />} />
          <Route path={'ontwerpen/:id/:token'} element={<Editor />} />

          <Route path={'transaction/:token'} element={<TransactionView />} />
          <Route path={'zakelijk'}>
            <Route
              path={'aanmelden'}
              element={
                <LinearSuspense>
                  <RegisterCompanyView />
                </LinearSuspense>
              }
            />
          </Route>
          <Route
            index
            element={
              <LinearSuspense>
                <Home />
              </LinearSuspense>
            }
          ></Route>
          <Route path={'account'}>
            <Route
              path={'inbox'}
              element={
                <LinearSuspense>
                  <InboxView />
                </LinearSuspense>
              }
            />
          </Route>
          <Route path="shop">
            <Route
              index
              element={
                <LinearSuspense>
                  <Shop />
                </LinearSuspense>
              }
            ></Route>
            <Route
              path="producten/:product"
              element={
                <LinearSuspense>
                  <Product />
                </LinearSuspense>
              }
            ></Route>
            <Route
              path="producten"
              element={
                <LinearSuspense>
                  <AllProducts />
                </LinearSuspense>
              }
            ></Route>
            <Route
              path="categorie/:category"
              element={
                <LinearSuspense>
                  <Category />
                </LinearSuspense>
              }
            ></Route>
          </Route>

          <Route
            path={'admin'}
            element={
              <LinearSuspense>
                <AdminWrapper />
              </LinearSuspense>
            }
          >
            {adminRoutes}
          </Route>
          <Route
            path={'*'}
            element={
              <Alert severity={'error'}>
                Deze pagina kon niet worden gevonden.
              </Alert>
            }
          ></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
