import {List, ListItem, ListItemText} from "@mui/material";
import {useLiveSession} from "./hooks/liveSessionContext";
import React from "react";

export function SessionUserList(){
const session = useLiveSession();

return <List>
    {(session.session?.participants??[]).map(user => <ListItem>
        <ListItemText primary={user.Firstname}/>
    </ListItem>)}
</List>


}