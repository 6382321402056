import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

export class ServiceWorkerController {

    sw: Promise<ServiceWorkerRegistration>
    vapidPublicKey = "BLyXKjXIRW5wY7QiZDzjIf2Ytlg3E_6RfrKgnalhMri2EZnZ41GBUpk-rbEBv2DPJYzh2b_Ser05rj0ts7H-gvA";
    convertedVapidKey = urlBase64ToUint8Array(this.vapidPublicKey);

    constructor() {
    }

    checkCompatibility() {
        return 'serviceWorker' in navigator;
    }

    register() {
        if (this.checkCompatibility()) {
            serviceWorkerRegistration.register();
        }
        this.sw = navigator.serviceWorker.ready.then((sw: ServiceWorkerRegistration) => sw);
    }

    async getNotificationPermission() {
        return await Notification.requestPermission();
    }

    async subscribeToPushNotifications() {
        if (this.checkCompatibility()) {
            const sw = await this.sw
            sw.pushManager.getSubscription().then(async (sub: PushSubscription | null) => {
                if (sub) return sub;
                alert("subscribing to push notifications");
                return sw.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: this.convertedVapidKey
                });
            });
        }
    }

}


export function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}


// if ('setAppBadge' in navigator) {
//     (navigator.setAppBadge as any)(12);
// }
// if ("serviceWorker" in navigator) {
//     navigator.serviceWorker.ready.then((sw) => {
//         sw.showNotification("jouwdesign",{
//             body: 'You successfully subscribed to our Notification service!',
//             dir: 'ltr',
//             lang: 'nl-NL',
//             tag: 'confirm-notification',
//             actions: [
//                 {
//                     action: 'confirm',
//                     title: 'Okay',
//                 },
//                 {
//                     action: 'cancel',
//                     title: 'Cancel',
//                 }
//             ]}
//         )
//
//
//
//         window.addEventListener('push', function (event) {
//             alert("i am push")
//         });
//
//     });
//
//
// }
//
//
