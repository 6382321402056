import {useEffect, useId, useRef} from "react";
import {useEditor} from "./hook/editor.context";
import {fabric} from "fabric";
import {useGlobalKeybindHandler} from "./modules/keybinds/globalKeybindHandler";
import { useLiveSession } from "./modules/session/hooks/liveSessionContext";


export function Canvas(){
    const id = useId();
    const editor = useEditor();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const session = useLiveSession();
    useGlobalKeybindHandler();



    useEffect(() => {
        if (canvasRef.current == null) return;
        const canvas = new fabric.Canvas(canvasRef.current, {
            width: 1000,
            height: 1000
        });
        editor.setCanvas(canvas);
        console.log("Canvas created");

    }, []);


    return <div className={"flex justify-center"}>
        <div className={"bg-white rounded shadow"}>
            <canvas id={id} ref={canvasRef} width={1080} height={1080}/>
        </div>

    </div>




}