import Skeleton from '@mui/material/Skeleton';

import React, { useMemo, useState } from 'react';
import { ProductFamilyType } from '../../../../../shared/src/Entities/Product/ProductFamily/ProductFamily.interface';
import { Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { SmartImage } from '../../Components/Images/SmartImage';
import { usePaginatedSWR } from '../../hooks/pagination.hook';

export default function Shop() {
  const {
    swr: { isLoading, mutate, error, data },
    datagrid,
    items,
  } = usePaginatedSWR<ProductFamilyType>('/v1/products/families', {}, [
    'Thumbnail',
  ]);

  if (data == null || isLoading) return <Skeleton sx={{ width: '100%' }} />;

  return (
    <Container disableGutters={true}>
      <Grid container>
        <Grid item>
          <Typography variant={'h4'}>Producten</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <Paper className={'p-1 sticky top-20'}>
            <Stack>
              <Typography variant={'h5'}>Filters</Typography>
              <Divider />
              <Typography variant={'body1'}>Kleur</Typography>
              <Typography variant={'body1'}>Materiaal</Typography>
              <Typography variant={'body1'}>Vorm</Typography>
              <Typography variant={'body1'}>Afmeting</Typography>
              <Typography variant={'body1'}>Prijs</Typography>
              <Typography variant={'body1'}>Merk</Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={10}>
          <Stack spacing={1}>
            {data.Items.map((productFamily) => (
              <ProductFamilySection productFamily={productFamily} />
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

function ProductFamilySection({
  productFamily,
}: {
  productFamily: ProductFamilyType;
}) {
  const thumbnail = useMemo(
    () =>
      productFamily.CachedVariationImages[
        Math.floor(Math.random() * productFamily.CachedVariationImages.length)
      ],
    []
  );
  // const [thumb, setThumbnail] = useState<{
  //   color: string;
  //   image: string;
  // }>(thumbnail);

  // return <CardActionArea component={Link} to={`/shop/producten/${productFamily.Name}`}>
  return (
    <Paper>
      <Grid container>
        <Grid item xs={3}>
          {productFamily.Thumbnail && (
            <SmartImage src={productFamily.Thumbnail} />
          )}
        </Grid>
        <Grid item xs={5}>
          <div className={'flex flex-col h-full'}>
            <Typography variant={'h5'}>{productFamily.Name}</Typography>

            <div className={'flex-grow'}>
              <Typography
                variant={'body1'}
                color={'gray'}
                sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 7,
                }}
              >
                {productFamily.Description}
              </Typography>
            </div>
            <div>
              {/*<ProductImageGrid*/}
              {/*  images={productFamily.CachedVariationImages}*/}
              {/*  setThumbnail={setThumbnail}*/}
              {/*/>*/}
            </div>
          </div>
        </Grid>
        <Grid item xs={4} className={'p-2'}>
          <div className={'flex flex-col h-full'}>
            <Typography sx={{ width: '100%' }}>
              &euro; {productFamily.Price}
            </Typography>
            <div className={'flex-grow'}></div>
            <div className={'flex-inline space-x-1 justify-end w-full'}>
              <Button
                component={Link}
                to={`/shop/producten/${productFamily.Slug}`}
                className={'w-100'}
                variant={'outlined'}
              >
                Bekijken
              </Button>
              <Button
                className={'w-100'}
                color={'primary'}
                variant={'contained'}
              >
                Ontwerpen
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
  {
    /*</CardActionArea>*/
  }
}

function ProductImageGrid(props: {
  images: {
    color: string;
    image: string;
  }[];
  setThumbnail: (img: { color: string; image: string }) => void;
}) {
  return (
    <div className={'list-none overflow-x-auto overflow-y-hidden flex h-20'}>
      {props.images.map((image) => {
        return (
          <img
            onClick={() => props.setThumbnail(image)}
            className={'block h-full cursor-pointer shadow'}
            src={image.image}
          />
        );
      })}
    </div>
  );
}
