import { useId } from 'react';
import { IFile } from 'shared/src/Entities/File/file.interface.js';
import { useApi } from '../Components/Request/useApi';

export function useFileUpload(isPublic = false, shouldResize = false) {
  const api = useApi();
  const id = useId();
  const endpoint = isPublic ? '/files/uploadPublic' : '/files/upload';
  if (!isPublic) throw new Error('Only public uploads are supported for now');

  async function upload(files: FileList) {
    const data = new FormData();
    data.append('files', files[0]);

    return await api
      .post<IFile[]>(endpoint, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          optimize: shouldResize,
        },
      })
      .then((res) => res.data[0].id);
  }

  return {
    upload,
    formId: id,
    inputProps: {
      type: 'file',
      onChange: async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files === null) return;

        return await upload(e.target.files);
      },
    },
  };
}
