import {Skeleton} from "@mui/material";
import {createContext, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {useWebsocket, useWebsocketEvent} from "../../Socket/Socket.provider.ts";
import {useSnackbar} from "../../../Components/Snackbar/snackbar.context.ts";
import {fabric} from "fabric";

type EditorContextType = {
    canvas: fabric.Canvas | null;
    setCanvas: (canvas: fabric.Canvas | null) => void;
    settings: settings;
    setSettings: (settings: settings) => void;
}
export const EditorContext = createContext<EditorContextType | null>(null);

type EditorProviderProps = {
    children: React.ReactNode;
}

type settings = {
    showGrid: boolean;
    colors: {
        fill: string;
        stroke: string;
    }
}

export const EditorProvider = ({children}: EditorProviderProps) => {
    const {token} = useParams();
    const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
    const [userIcons, setUserIcons] = useState<Record<string, fabric.Object>>({});
    const snackbar = useSnackbar();

    const [settings, setSettings] = useState<settings>({
        showGrid: true,
        colors: {
            fill: "#000000",
            stroke: "#ffffff"
        }
    });


    return <EditorContext.Provider value={{
        canvas,
        setCanvas,
        settings,
        setSettings
    }}>
        {children}
    </EditorContext.Provider>
}


export const useEditor = () => {
    const context = useContext(EditorContext);
    if (context == null) {
        throw new Error("useEditor must be used within a EditorProvider");
    }
    return context;
}

