import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom/client';
import JDRouter from './Router';
import axios from 'axios';
import cookies from 'js-cookie';
import { SWRConfig } from 'swr/_internal';
import { JDFetcher } from './Utils/JDFetcher';
import { ServiceWorkerController } from './Controllers/ServiceWorkerController';
import { AccountProvider } from './Modules/Account/account.provider';
import { SharedConfig } from 'shared/src/shared.config';
import './styles.scss';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

// const faro = initializeFaro({
//     url: 'https://faro-collector-prod-eu-west-2.grafana.net/collect/80f6b3d823fbb2614780351c755d40b2',
//     app: {
//         name: 'jouwdesign',
//         version: '1.0.0',
//         environment: 'production'
//     },
//
//     instrumentations: [
//         // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
//         ...getWebInstrumentations(),
//
//         // Initialization of the tracing package.
//         // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
//         new TracingInstrumentation(),
//     ],
// });

axios.defaults.headers.common[SharedConfig.headers.userToken] = cookies.get(
  SharedConfig.headers.userToken
);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <SWRConfig
    value={{
      fetcher: JDFetcher,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }}
  >
    <AccountProvider>
      <JDRouter />
    </AccountProvider>
  </SWRConfig>
);

const ServiceWorker = new ServiceWorkerController();
ServiceWorker.register();
