import React, {useEffect, useMemo} from "react";
import useSWR from "swr";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {ILogitemEntity} from "shared/src/Entities/LogItem/logitem.entity.interface";
import {IRolandJobHistory} from "shared/src/Entities/RolandJobHistory/JobHistory.entity.interface";
import {PrintJob} from "shared/src/Modules/Print/PrintJob.type";
import { Card } from "@mui/material";


type PrintJobTableProps = {
    updater: number;
}
export function PrintJobTable({updater}: PrintJobTableProps) {
    const {data: jobs, isLoading, mutate} = useSWR<PrintJob[]>("/print/queue", {refreshInterval: 1000})

    useEffect(() => {
        mutate()
    }, [updater]);
    const columns: GridColDef[] = React.useMemo<GridColDef<PrintJob>[]>(() => [
        {field: "uuid", flex: 1, headerName: "UUID"},
        {field: "printer.deviceId", flex: 1, headerName: "Printer", valueGetter: ({value, row}) => row.printer?.name},
        {field: "url", flex: 1, headerName: "URL"},
        {field: "status", flex: 1, headerName: "Status"},
    ], []);

    return (<Card sx={{height:"100%"}}>
            <DataGrid loading={isLoading} columns={columns}
                      rows={jobs??[]}
                      getRowId={(row: PrintJob) => row.uuid}/>
    </Card>

    );
}