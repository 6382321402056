import {useEditor} from "../../hook/editor.context";
import {useEffect} from "react";
import {fabric} from "fabric";

export function useGlobalKeybindHandler(){
    const editor = useEditor();

    useEffect(() => {
        if (editor.canvas == null) return;
        console.log("registering keybinds")
        window.onkeydown = async (event) => {
            const canvas = editor.canvas;
            if (canvas == null) throw new Error("Canvas is null");
            switch (event.key) {
                case 'Delete':
                    canvas.getActiveObjects().forEach(o => canvas.remove(o));
                    canvas.discardActiveObject();
                    canvas.requestRenderAll();

                    break;
                case 'a':
                    if (event.ctrlKey) {
                        event.preventDefault();
                     const selection = new fabric.ActiveSelection(canvas.getObjects(), {
                         canvas
                     });
                        canvas.setActiveObject(selection);
                        canvas.requestRenderAll();
                    }
                    break;
                case 'c':
                    if (event.ctrlKey) {
                        event.preventDefault();
                        const selection = new fabric.ActiveSelection([], {canvas});
                        canvas.getActiveObjects().forEach((o) => {
                            canvas.discardActiveObject()

                            o.clone((cloned: any) => {
                                canvas.add(cloned.set({left: o.left ?? 0 + 10, top: o.top ?? 0 + 10}));
                                selection.addWithUpdate(cloned);
                            });


                        });
                        canvas.setActiveObject(selection);
                        canvas.requestRenderAll();
                    }
                    break;
                case 'ArrowUp':
                    event.preventDefault();
                    canvas.getActiveObjects().forEach(o => o.top ? o.top -= event.shiftKey ? 10 : 1 : 0);
                    canvas.requestRenderAll();
                    break;
                case 'ArrowDown':
                    event.preventDefault();
                    canvas.getActiveObjects().forEach(o => o.top ? o.top += event.shiftKey ? 10 : 1 : 0);
                    canvas.requestRenderAll();
                    break;
                case 'ArrowLeft':
                    event.preventDefault();
                    canvas.getActiveObjects().forEach(o => o.left ? o.left -= event.shiftKey ? 10 : 1 : 0);
                    canvas.requestRenderAll();
                    break;
                case 'ArrowRight':
                    event.preventDefault();
                    canvas.getActiveObjects().forEach(o => o.left ? o.left += event.shiftKey ? 10 : 1 : 0);
                    canvas.requestRenderAll();
                    break;
                case "Escape":
                    event.preventDefault();
                    console.log("Escape pressed");
                   canvas.discardActiveObject();
                    canvas.requestRenderAll();
                    break;
                default:
            }
        }


        return ()=>{
            window.onkeydown = null;
        }

    }, [editor.canvas]);
}