import {z} from "zod";
export const CreateShipmentSchema = z.object({
    person: z.string(),
    street: z.string(),
    number: z.string(),
    postalCode: z.string(),
    city: z.string(),
    // package_type: z.enum(["package", "mailbox", "letter", "digital_stamp", "pallet", "small_parcel"]),
})

export type CreateShipmentType = z.infer<typeof CreateShipmentSchema>;


