import {z} from "zod";
import {zSlug} from "../../ZodTypes/slug";

export const ImportMalfiniProductSchema = z.object({
    identifier: z.string(),
    name: z.string(),
    slug: zSlug,
    price: z.coerce.number(),
})
export type ImportMalfiniProductType = z.infer<typeof ImportMalfiniProductSchema>

