import React, {useMemo} from "react";
import {useSnackbar} from "../../../../Components/Snackbar/snackbar.context.ts";
import {usePaginatedSWR} from "../../../../hooks/pagination.hook";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {DetailedDataGrid, useSelectedDatagridId} from "../../../../Components/TabbableDataGrid/DetailedDataGrid";
import {Button, Card, CardContent, CardHeader, IconButton, Paper} from "@mui/material";
import {Add, Refresh} from "@mui/icons-material";
import useSWR from "swr";
import Skeleton from "@mui/material/Skeleton";
import {ICompanyEntity} from "shared/src/Entities/Company/company.entity.interface.js";
import {useApi} from "../../../../Components/Request/useApi";

export default function OrganizationTable() {
    const api = useApi();
    const snackbar = useSnackbar();

    const {
        datagrid,
        swr: {
            mutate
        },
        items: organizations
    } = usePaginatedSWR<ICompanyEntity>("/organizations");



    const columns: GridColDef[] = React.useMemo<GridColDef<ICompanyEntity>[]>(() => [
        {field: 'Semantic', flex: 1, headerName: 'slug'},

    ], [organizations]);

    if (organizations==null) return <Skeleton/>;

    return <DetailedDataGrid<ICompanyEntity>
        dataGridWrapperComponent={Paper}
        gridActions={[
            <IconButton onClick={() => mutate()}><Refresh/></IconButton>,
            <Button variant={"contained"} startIcon={<Add/>}>Organisatie</Button>,
        ]} gridColDef={columns} items={organizations} getRowId={(row: ICompanyEntity) => row.Semantic}
        datagridProps={datagrid} detailComponent={<OrganizationDetails/>}/>

}


function OrganizationDetails(){
    const selectedId = useSelectedDatagridId<string>();
    const {data: organization} = useSWR<ICompanyEntity>(selectedId ? `/organizations/${selectedId}` : null);
    if (organization == null) return <Skeleton/>;

    return <Card>
        <CardHeader title={organization.Name}/>
        <CardContent>
            <OrganizationMemberTable organization={organization}/>
        </CardContent>
    </Card>
}

function OrganizationMemberTable(props: {organization: ICompanyEntity}){
    const columns = useMemo(()=>([
        {
            field: "User.Email",
            headerName: "Email",
            flex: 1,
            valueGetter: (params: any)=>params.row.User?.Email
        },
        {
            field:"Role",
            headerName: "Rol",
            flex: 1,
        }
    ]),[])

    return <DataGrid columns={columns} rows={props.organization.Members} getRowId={(member)=>member.Id} />

}


