import {Avatar, Badge, IconButton, Tooltip} from "@mui/material";
import React, {useId} from "react";
import {useAccount} from "../account.hook";
import AccountMenu from "./AccountMenu";
import {useUnreadItemCount} from "../../../hooks/inbox.hook";

export default function AccountAvatar() {
    const user = useAccount();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const {data: unreadMessages} = useUnreadItemCount();
    const id = useId()

    function handleAccountButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault()
        if (anchorEl == null) {
            setAnchorEl(document.getElementById(id));
        } else {
            setAnchorEl(null);
        }
    }

    return <>
        <Tooltip title={"accountmenu"}><IconButton onClick={handleAccountButtonClick}> <Badge
            badgeContent={unreadMessages} color="secondary"><Avatar id={id} alt={"profielfoto"} sx={{
            height: "2em",
            width: "2em"
        }}>{user?.Firstname?.substring(0, 1).toUpperCase()}</Avatar> </Badge></IconButton></Tooltip>
        <AccountMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}></AccountMenu>
    </>
}