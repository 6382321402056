import React, {ErrorInfo} from "react";
import {Alert} from "@mui/material";

import axios from "axios";
import {ReportErrorDTO} from "shared/src/Entities/LogItem/dto/CreateError.dto";


export class LoggingBoundary extends React.Component {
    state: { hasError: boolean; };

    constructor(props: any) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        const createErrorDTO = new ReportErrorDTO();
        createErrorDTO.Description = `${error.message} ${error.stack}`;
        axios.post("/api/logs/report", createErrorDTO);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Alert severity={"error"}>Er is een fout opgetreden. Er is een rapport van deze fout naar onze
                administrators toegestuurd. Excuses voor het ongemak, wij proberen het probleem zo snel mogelijk te
                verhelpen. U kunt proberen om de pagina te verversen of om naar de <a href={"/"}>homepagina</a> te gaan
            </Alert>;
        }

        return (this.props as any).children;
    }
}