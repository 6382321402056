import {LoginRegisterFields} from "../../../Components/Header/LoginRegisterFields";
import {Collapse, Divider, IconButton, Paper, Popover, Skeleton, Stack, Tooltip} from "@mui/material";
import UserSettings from "./UserSettings";
import {Logout, Settings} from "@mui/icons-material";
import {useAccount, useAccountActions} from "../account.hook";
import React from "react";
import {AccountType} from "shared/src/Models/user.types";

interface IAccountMenuProps {
    anchorEl: HTMLElement | null
    setAnchorEl: (el: HTMLElement | null) => void
}


export default function AccountMenu(props: IAccountMenuProps) {
    const user = useAccount();
    const {Logout: AccountLogout} = useAccountActions();


    if (user == null) return <Skeleton variant={"rectangular"} width={300} height={200}/>
    return <Popover onClose={() => props.setAnchorEl(null)} anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                    transformOrigin={{vertical: "top", horizontal: "left"}} open={props.anchorEl != null}
                    anchorEl={props.anchorEl}>
        <Paper sx={{padding: "1em"}}>
            <Collapse in={user?.AccountType === AccountType.Guest.toString()}><LoginRegisterFields/></Collapse>
            <Collapse in={user?.AccountType !== AccountType.Guest.toString()}><UserSettings/></Collapse>
            <Divider sx={{margin: 1}}/>
            <Stack direction={"row-reverse"}>
                <Tooltip title={"Instellingen"}><IconButton><Settings/></IconButton></Tooltip>
                {user?.AccountType !== AccountType.Guest.toString() ?
                    <Tooltip title={"uitloggen"}>
                        <IconButton onClick={AccountLogout}><Logout/></IconButton>
                    </Tooltip> : <></>
                }
            </Stack>
        </Paper>
    </Popover>
}



