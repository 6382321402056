import { useMemo, useState } from 'react';
import { IPaginatedResponse } from 'shared/src/Interfaces/IPaginatedResponse';
import {
  GridCallbackDetails,
  GridPaginationModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import useSWR, { SWRResponse } from 'swr';
import { DataGridPropsType } from '../Components/TabbableDataGrid/DetailedDataGrid';

type usePaginationResponse<T extends GridValidRowModel> = {
  pagination: {
    page: number;
    itemsPerPage: number;
  };
  datagrid: DataGridPropsType<T>;
  items: T[] | undefined;
  query: string;
  swr: SWRResponse<IPaginatedResponse<T>>;
};

type orderBy = {
  field: string;
  direction: 'asc' | 'desc';
};

export function usePaginatedSWR<T extends GridValidRowModel>(
  key: string,
  options: any = {},
  relations: string[] = []
): usePaginationResponse<T> {
  //states
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [orderBy, setOrderBy] = useState<orderBy | null>(null);
  //build query
  const query = useMemo(() => {
    const relationsQuery = relations.reduce(
      (acc, cur) => acc + `${cur},`,
      '&relations='
    );
    let query = `?page=${page}&amount=${itemsPerPage}`;
    if (orderBy)
      query += `&orderBy=${orderBy.field}&direction=${orderBy.direction}`;
    if (relations.length > 0) query += relationsQuery;
    return query;
  }, [itemsPerPage, orderBy, page]);

  const swr = useSWR<IPaginatedResponse<T>>(`${key}${query}`, options);
  const items = useMemo(() => swr.data?.Items, [swr.data]);

  return {
    items: items,
    pagination: { page, itemsPerPage },
    datagrid: {
      rows: items ?? [],
      paginationMode: 'server',
      rowCount: swr.data?.Total,
      paginationModel: {
        page: page,
        pageSize: itemsPerPage,
      },
      loading: swr.isLoading,
      onPaginationModelChange: (
        model: GridPaginationModel,
        details: GridCallbackDetails<any>
      ) => {
        setPage(model.page);
        setItemsPerPage(model.pageSize);
      },
    },
    query: `page=${page}&amount=${itemsPerPage}`,
    swr: swr,
  };
}
