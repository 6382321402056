import useSWR from "swr";
import {IInboxMessageEntity} from "shared/src/Entities/Inbox/inboxMessage.entity.interface";

export function useUnreadItemCount() {
    const result = useSWR<number>("/inbox/unreadCount")
    return result;
}

export function useInboxMessages() {
    const result = useSWR<IInboxMessageEntity[]>("/inbox")
    return result;
}