import { ProductFamilyType } from 'shared/src/Entities/Product/ProductFamily/ProductFamily.interface';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';
import { getMediaUrl } from '../../Utils/getMediaUrl';

export function ProductFamilyCard({
  productFamily,
}: {
  productFamily: ProductFamilyType;
}) {
  return (
    <Card>
      <CardActionArea component={Link} to={productFamily.Name}>
        <CardMedia
          component={'img'}
          src={getMediaUrl(productFamily.Thumbnail)}
          sx={{ width: '100%' }}
        ></CardMedia>
        <CardContent>
          <Typography variant={'h5'}>{productFamily.Name}</Typography>
          <Typography
            variant={'body1'}
            color={'gray'}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
            }}
          >
            {productFamily.Description}
          </Typography>

          <Typography
            variant={'caption'}
            align={'right'}
            sx={{ width: '100%' }}
          >
            &euro; {productFamily.Price}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
