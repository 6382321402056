import {Alert, Avatar, Badge, Divider, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, Stack, Typography} from "@mui/material";
import {AdminPanelSettings, Dashboard, Home, Mail, Palette} from "@mui/icons-material";
import RolesOnly from "../../../Components/Restricted/RolesOnly";
import {Link} from "react-router-dom";
import {useUnreadItemCount} from "../../../hooks/inbox.hook";
import React from "react";
import {useAccount} from "../account.hook";
import {AccountType} from "shared/src/Models/user.types";
import {SelectOrganization} from "../../Organizations/SelectOrganization";
import {useOrganization} from "../../Organizations/OrganizationContext/organization.hook";

export default function UserSettings() {
    const {data: unreadMessages} = useUnreadItemCount();
    const {selectedOrganization} = useOrganization();
    const user = useAccount();

    if (user == null) return <Alert severity={"error"}>De gebruiker kon niet worden opgehaald.</Alert>

    return <>
        <Stack>
            <Typography variant={"h6"}>Welkom {user.Firstname}</Typography>
            <SelectOrganization/>
            {
                selectedOrganization && (<>
                        <MenuItem component={Link}
                                  to={`/c/${selectedOrganization.Semantic}`}><ListItemIcon><Home/></ListItemIcon><ListItemText>{selectedOrganization.Name} home</ListItemText></MenuItem>
                        <MenuItem component={Link}
                                  to={`/c/${selectedOrganization.Semantic}/backoffice`}><ListItemIcon><Dashboard/></ListItemIcon><ListItemText>{selectedOrganization.Name} backoffice</ListItemText></MenuItem>
                    </>
                )
            }
            <Divider/>
            <MenuList>

                <RolesOnly roles={[AccountType.Admin]}>
                    <MenuItem component={Link}
                              to={"/admin"}><ListItemIcon><AdminPanelSettings/></ListItemIcon><ListItemText>Adminpanel</ListItemText></MenuItem>
                </RolesOnly>

                <MenuItem component={Link} to={"/account/inbox"}><ListItemIcon><Badge badgeContent={unreadMessages}
                                                                                      color={"secondary"}><Mail/></Badge></ListItemIcon><ListItemText>Inbox</ListItemText></MenuItem>
                <MenuItem><ListItemIcon><Palette/></ListItemIcon><ListItemText>Ontwerpen</ListItemText></MenuItem>
            </MenuList>
        </Stack>
    </>
}