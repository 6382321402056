import { Alert, Box, Skeleton } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { SharedConfig } from '../../../../../shared/src/shared.config';
import { IFile } from '../../../../../shared/src/Entities/File/file.interface';
import { getMediaUrl } from '../../Utils/getMediaUrl';

export type SmartImageProps = {
  src?: string | IFile;
  imgProps?: Partial<React.ImgHTMLAttributes<HTMLImageElement>>;
  variant?: keyof typeof SharedConfig.files.variants | 'original';
};

export function SmartImage(props: SmartImageProps) {
  const boxSizingRef = React.useRef<HTMLDivElement>(null);

  const [imgUrl, setImgUrl] = React.useState<string | null>(null);
  const [errored, setErrored] = React.useState(false);

  const [size, setSize] = React.useState<{
    width: number;
    height: number;
  } | null>(null);
  const [variant, setVariant] = React.useState<
    keyof typeof SharedConfig.files.variants | null | 'original'
  >(null);

  const preload = useCallback(() => {
    if (variant === null) return;
    const img = new Image();
    console.log('preloading', props.src, variant);
    img.src = getMediaUrl(props.src, variant);

    img.onload = () => {
      setImgUrl(img.src);
    };
    img.onerror = () => {
      setErrored(true);
    };
  }, [props.src, variant]);

  const getAppropriateVariant = useCallback(
    ({ width, height }: { width: number; height: number }) => {
      if (props.variant) return props.variant;

      const allVariants = Object.keys(
        SharedConfig.files.variants
      ) as (keyof typeof SharedConfig.files.variants)[];

      for (const variant of allVariants) {
        if (width <= SharedConfig.files.variants[variant][0]) {
          return variant;
        }
      }
      return 'small';
    },
    [props.variant]
  );

  useEffect(() => {
    preload();
  }, [preload, variant]);

  useEffect(() => {
    const { width, height } = boxSizingRef.current?.getBoundingClientRect() ?? {
      width: 100,
      height: 100,
    };
    setSize({ width, height });
    const variant = getAppropriateVariant({ width, height });

    setVariant(variant);
  }, [getAppropriateVariant]);

  if (!props.src) return null;

  if (imgUrl) return <img {...props.imgProps} src={imgUrl} alt="" />;
  return (
    <Box sx={{ width: 1, height: 'auto' }}>
      {imgUrl ? (
        <img {...props.imgProps} className={'h-auto'} src={imgUrl} alt="" />
      ) : (
        (errored && (
          <Alert severity={'error'} sx={{ ratio: '1/1' }}>
            De afbeelding kon niet worden geladen
          </Alert>
        )) || <Skeleton />
      )}
    </Box>
  );
}
