import useSWR from "swr";
import {Outlet, useParams} from "react-router-dom";
import {Alert, Container, Skeleton} from "@mui/material";
import {CompanyAccessType} from "shared/src/Entities/Company/companyAccessType";

export function OrganizationWrapper(){
    const {orgId} = useParams();
    const {data: organization, isLoading, error} = useSWR<{CompanyAccessType: CompanyAccessType}>(`/organizations/public/${orgId}`)

    if (error) return <Container>
        <Alert severity={"error"}>Deze organisatie bestaat niet of er is iets misgegaan</Alert>
    </Container>

    if (!organization) return <Container>
        <Skeleton/>
    </Container>

    switch (organization.CompanyAccessType){
        case CompanyAccessType.PASSWORD: case CompanyAccessType.PUBLIC: case CompanyAccessType.INVITE:
            return <Outlet/>
            break;


    }

}