import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Skeleton,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { usePaginatedSWR } from '../../../../hooks/pagination.hook';
import { DetailedDataGrid } from '../../../../Components/TabbableDataGrid/DetailedDataGrid';
import { ProductFamilyType } from 'shared/src/Entities/Product/ProductFamily/ProductFamily.interface';
import { ProductFamilyForm } from '../../ProductFamilyForm';
import {
  Add,
  Cached,
  Check,
  Delete,
  DisabledByDefault,
  KeyboardReturn,
  Password,
  Refresh,
  Security,
} from '@mui/icons-material';
import axios from 'axios';
import { useConfirmDialog } from '../../../../Components/Dialog/DialogContext';
import { ProductFamilyDetails } from './ProductFamilyDetails';
import { MalfiniWizard } from '../../malfini/MalfiniWizard';
import { useApi } from '../../../../Components/Request/useApi';
import { ProductFamilyStatus } from '../../../../../../../shared/src/Modules/Product/ProductFamilyStatus.enum';
import { getMediaUrl } from '../../../../Utils/getMediaUrl';
import { SmartImage } from 'apps/client/src/Components/Images/SmartImage';

export default function ProductFamilyTable() {
  const {
    swr: { isLoading, mutate, error },
    datagrid,
    items,
  } = usePaginatedSWR<ProductFamilyType>(
    '/v1/products/families/all',
    {
      revalidateOnMount: true,
    },
    ['Thumbnail']
  );
  const api = useApi();

  const [adding, setAdding] = useState(false);
  const [importingMalfini, setImportingMalfini] = useState(false);

  const confirmDelete = useConfirmDialog(
    'Weet u zeker dat u deze productfamilie wilt verwijderen?'
  );

  const handleDeleteItem = React.useCallback(
    (id: number) => {
      confirmDelete(
        `Weet u zeker dat u deze productfamilie wilt verwijderen?`,
        () => {
          axios.delete(`/api/products/families/${id}`).then(() => mutate());
        }
      );
    },
    [confirmDelete, mutate]
  );

  const handleActivateProductFamily = useCallback(
    (slug: string) => {
      api
        .patch(`/v1/products/families/${slug}`, {
          Status: ProductFamilyStatus.ACTIVE,
        })
        .then(() => mutate());
    },
    [api, mutate]
  );

  const columns: GridColDef[] = React.useMemo<GridColDef<ProductFamilyType>[]>(
    () => [
      {
        field: 'Status',
        headerName: 'Status',
        renderCell: ({ row }) => {
          return <StatusChip status={row.Status as ProductFamilyStatus} />;
        },
      },
      {
        field: 'thumb',
        headerName: 'Thumb',
        renderCell: ({ row }) => (
          <SmartImage
            src={row.Thumbnail}
            imgProps={{ style: { maxHeight: '100%' } }}
          />
        ),
      },
      { field: 'Name', headerName: 'Naam', flex: 1 },
      { field: 'Description', headerName: 'Beschrijving', flex: 1 },
      { field: 'ExternalProvider', headerName: 'Leverancier', flex: 1 },
      {
        field: 'Price',
        headerName: 'Prijs (€)',
        renderCell: ({ row }) => {
          return <>€{row.Price}</>;
        },
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params) => [
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            showInMenu
            onClick={() => handleDeleteItem(params.row.Id)}
          />,
          <GridActionsCellItem
            showInMenu
            icon={<Cached />}
            label="Force cache"
            onClick={() =>
              api
                .post(`/v1/products/families/${params.row.Slug}/precache`)
                .then(() => mutate())
            }
          />,
          <GridActionsCellItem
            showInMenu
            icon={<Check />}
            label="Activeren"
            onClick={() => handleActivateProductFamily(params.row.Slug)}
          />,
        ],
      },
    ],
    [api, handleDeleteItem, mutate]
  );

  if (items == null || isLoading) return <Skeleton sx={{ width: '100%' }} />;
  if (error) throw new Error('Error loading logs');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Collapse in={adding}>
          <Card>
            <CardHeader
              title={'Product toevoegen'}
              action={
                <IconButton onClick={() => setAdding(false)}>
                  <KeyboardReturn />
                </IconButton>
              }
            />
            <CardContent>
              <ProductFamilyForm />
            </CardContent>
          </Card>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={importingMalfini}>
          <Card>
            <CardHeader
              title={'Product importeren van malfini'}
              action={
                <IconButton onClick={() => setImportingMalfini(false)}>
                  <KeyboardReturn />
                </IconButton>
              }
            />
            <CardContent>
              <MalfiniWizard />
            </CardContent>
          </Card>
        </Collapse>
      </Grid>

      <Grid item xs={12}>
        <Collapse in={!importingMalfini}>
          <DetailedDataGrid
            onUpdate={mutate}
            gridActions={[
              <IconButton onClick={() => mutate()} key={'refresh'}>
                <Refresh />
              </IconButton>,
              <Button onClick={() => setAdding(true)} startIcon={<Add />}>
                Toevoegen
              </Button>,
              <Button
                onClick={() => setImportingMalfini(true)}
                startIcon={<Add />}
              >
                Importeren van malfini
              </Button>,
            ]}
            dataGridWrapperComponent={Paper}
            gridColDef={columns}
            items={items}
            getRowId={(family: ProductFamilyType) => family.Slug}
            datagridProps={{ ...datagrid }}
            detailComponent={<ProductFamilyDetails />}
          />
        </Collapse>
      </Grid>
    </Grid>
  );
}

const StatusChip = ({ status }: { status: ProductFamilyStatus }) =>
  useMemo(() => {
    switch (status) {
      case ProductFamilyStatus.ACTIVE:
        return (
          <Chip
            icon={<Check />}
            label={'Actief'}
            variant={'outlined'}
            size={'small'}
            color={'success'}
          />
        );
      case ProductFamilyStatus.DISABLED:
        return (
          <Chip
            icon={<DisabledByDefault />}
            variant={'outlined'}
            size={'small'}
            label={'Uitgeschakeld'}
            color={'error'}
          />
        );
      default:
        return <Chip icon={<Check />} label={'Actief'} color={'success'} />;
    }
  }, [status]);
