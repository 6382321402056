import React from "react";
import {Alert} from "@mui/material";
import {useAccount} from "../../Modules/Account/account.hook";
import {AccountType} from "shared/src/Models/user.types";

interface RolesOnlyProps {
    roles: AccountType[];
    error?: boolean;
    children: any;
    errorMessage?: string;
}

export default function RolesOnly(props: RolesOnlyProps) {
    const user = useAccount();
    const message = props.errorMessage ?? "Geen toegang";

    if (user == null || !props.roles.some(role => role.toString() === user.AccountType)) return props.error ?
        <UnauthorizedAlert msg={message}/> : <></>;

    return props.children;
}


function UnauthorizedAlert(props: { msg: string }) {
    return <Alert severity="error">{props.msg}</Alert>;
}