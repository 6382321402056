import { Box, Collapse, Grid, Paper, Stack, useTheme } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { Tabbable } from '../Tabs/Tabbable';
import React, { useEffect, useMemo } from 'react';
import { TabDefinition } from '../Tabs/TabDefinition';
import { GridRowIdGetter } from '@mui/x-data-grid/models/gridRows';
import { useMobile } from '../../hooks/mobile.hook.ts';

export type DataGridPropsType<T extends GridValidRowModel> =
  | Omit<DataGridProps<T>, 'columns' | 'processRowUpdate'>
  | Omit<DataGridProps<T>, 'columns' | 'processRowUpdate' | 'rows'>;

type TabbableDataGridProps<RowModel extends GridValidRowModel> = {
  gridColDef: GridColDef[];
  datagridProps: DataGridPropsType<RowModel>;
  detailComponent: React.ReactNode;
  getRowId: GridRowIdGetter<RowModel>;
  items: RowModel[];
  gridActions?: React.ReactNode[];
  noSplitscreen?: boolean;
  dataGridWrapperComponent?: React.ComponentType<any>;
  onUpdate?: () => void;
  open?: boolean;
};

type keyType = string | number | null;

type TabbableDataGridContextType =
  | {
      selectedId: keyType;
      // Function to call on update
      onUpdate?: () => void;
    }
  | undefined;

const TabbableDataGridContext =
  React.createContext<TabbableDataGridContextType>(undefined);

export function DetailedDataGrid<ItemType extends GridValidRowModel>({
  detailComponent,
  ...props
}: TabbableDataGridProps<ItemType>) {
  const [selectedId, setSelectedId] = React.useState<keyType | null>(null);
  const mobile = useMobile();

  const shouldSplitscreen = useMemo(() => {
    if (props.noSplitscreen) return false;
    if (mobile) return false;
    return true;
  }, [mobile, props.noSplitscreen]);

  useEffect(() => {
    if (props.open === true) setSelectedId(null);
  }, [props.open]);

  if (!shouldSplitscreen)
    return (
      <TabbableDataGrid<ItemType>
        {...props}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        detailComponent={detailComponent}
      />
    );
  return (
    <SplitScreenDataGrid<ItemType>
      detailComponent={detailComponent}
      {...props}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
    />
  );
}

function SplitScreenDataGrid<ItemType extends GridValidRowModel>(
  props: TabbableDataGridProps<ItemType> & {
    selectedId: keyType;
    open?: boolean;
    setSelectedId: (id: keyType) => void;
  }
) {
  return (
    <TabbableDataGridContext.Provider
      value={{ selectedId: props.selectedId, onUpdate: props.onUpdate }}
    >
      <Grid container spacing={2}>
        <Grid item xs={props.selectedId == null ? 12 : 6}>
          <Box component={props.dataGridWrapperComponent}>
            {props.gridActions && (
              <Stack padding={'1em'} direction={'row'} spacing={2}>
                {props.gridActions.map((action, index) => (
                  <Box key={index}>{action}</Box>
                ))}
              </Stack>
            )}
            <DataGrid<ItemType>
              rows={props.items}
              {...props.datagridProps}
              columns={props.gridColDef}
              getRowId={props.getRowId}
              rowSelection={true}
              onRowSelectionModelChange={(rowSelectionModel) => {
                props.setSelectedId(rowSelectionModel[0] as keyType);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={'sticky top-20'}>
            {(props.open || props.selectedId) && props.detailComponent}
          </Box>
        </Grid>
      </Grid>
    </TabbableDataGridContext.Provider>
  );
}

function TabbableDataGrid<ItemType extends GridValidRowModel>(
  props: TabbableDataGridProps<ItemType> & {
    selectedId: keyType;
    setSelectedId: (id: keyType) => void;
  }
) {
  const [tab, setTab] = React.useState<number>(0);
  const tabDefinition = useMemo<TabDefinition[]>(
    () => [
      {
        label: 'Lijst',
        panel: (
          <Box component={props.dataGridWrapperComponent}>
            {props.gridActions && (
              <Stack padding={'1em'} direction={'row'} spacing={2}>
                {props.gridActions.map((action, index) => (
                  <Box key={index}>{action}</Box>
                ))}
              </Stack>
            )}
            <DataGrid
              rows={props.items}
              {...props.datagridProps}
              columns={props.gridColDef}
              getRowId={props.getRowId}
              sx={{ ...props.datagridProps.sx, maxHeight: '100vh' }}
              onRowDoubleClick={(e) => {
                props.setSelectedId(props.getRowId(e.row));
                setTab(1);
              }}
            />
          </Box>
        ),
      },
      {
        label: 'Details',
        panel: <Box>{props.detailComponent}</Box>,
        disabled: () => props.selectedId == null,
      },
    ],
    [props.detailComponent, props, props.selectedId]
  );

  return (
    <TabbableDataGridContext.Provider
      value={{
        selectedId: props.open ? null : props.selectedId,
        onUpdate: props.onUpdate,
      }}
    >
      <Tabbable
        tabs={tabDefinition}
        currentTab={tab}
        onTabChange={(_tab) => setTab(_tab)}
      />
    </TabbableDataGridContext.Provider>
  );
}

export function useSelectedDatagridId<T extends keyType>() {
  return React.useContext(TabbableDataGridContext)?.selectedId as T;
}

export function useDatagridUpdate() {
  return React.useContext(TabbableDataGridContext)?.onUpdate;
}
