const theme: any = {
    typography: {
        fontStyle: "bold",
        fontFamily: `Inter, sans-serif`,
        fontWeightLight: 30,
        fontWeightRegular: 40,
        fontWeightMedium: 50,
        fontWeightBold: 70,
        htmlFontSize: 16,
        fontSize: 14,
        h1: {
            // fontFamily: `filson-pro", roboto, sans-serif`  ,
        },
        h2: {},
        h3: {},
        h4: {},
        h5: {
            //   fontFamily: `"filson-soft", roboto, sans-serif`  ,
        },
        h6: {},
        subtitle1: {},
        subtitle2: {},
        body1: {
            fontWeight: 10,
            fontStyle: "normal",
        },
        body2: {
            fontWeight: 10
        },
        caption: {},
        button: {
            textTransform:"none"
        },
        overline: {},

    },
    palette: {
        mode: "light",
        common: {
            black: "#000000",
            white: "#ffffff"
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        error: {
            main: "#d32f2f",
            light: "#ef5350",
            dark: "#c62828",
            contrastText: "#fff"
        },
        warning: {
            main: "#ed6c02",
            light: "#ff9800",
            dark: "#e65100",
            contrastText: "#fff"
        },
        info: {
            main: "#0288d1",
            light: "#03a9f4",
            dark: "#01579b",
            contrastText: "#fff"
        },
        success: {
            main: "#2e7d32",
            light: "#4caf50",
            dark: "#1b5e20",
            contrastText: "#fff"
        },
        grey: {
            50: "#fafafa",
            100: "#f5f5f5",
            200: "#eeeeee",
            300: "#e0e0e0",
            400: "#bdbdbd",
            500: "#9e9e9e",
            600: "#757575",
            700: "#616161",
            800: "#424242",
            900: "#212121",
            A100: "#f5f5f5",
            A200: "#eeeeee",
            A400: "#bdbdbd",
            A700: "#616161"
        },
        text: {
            primary: "rgba(0,0,0,0.87)",
            secondary: "rgba(0,0,0,0.6)",
            disabled: "rgba(0,0,0,0.38)"
        },
        divider: "rgba(0,0,0,0.12)",
        background: {
            paper: "#fff",
            default: "#d9d9d9",
        },
        action: {
            active: "rgba(0,0,0,0.54)",
            hover: "rgba(0,0,0,0.04)",
            hoverOpacity: 0.04,
            selected: "rgba(0,0,0,0.08)",
            selectedOpacity: 0.08,
            disabled: "rgba(0,0,0,0.26)",
            disabledBackground: "rgba(0,0,0,0.12)",
            disabledOpacity: 0.08,
            focus: "rgba(0,0,0,0.12)",
            focusOpacity: 0.12,
            activatedOpacity: 0.12,
        },
        primary: {

            main: '#003caa',
            dark: "#0022aa",
            light: "#4A69B6",
            contrastText: "#fff"
        },
        overrides: {
            MuiButton: {
                raisedPrimary: {
                    color: '#6E402C',
                },
            },
        },
        secondary: {
            main: "#2c6e5a",
            dark: "#1B4337",
            light: "#4AB695",
            contrastText: "#fff"
        }
    },
    sizes: {
        smallScreen: 3,
        bigScreen: 2
    }
}

export default {
    theme: theme,
    url: "https://dev.jouwdesign.nu"
}

