import {TextField, TextFieldProps } from "@mui/material";
import {Controller, useFormContext } from "react-hook-form";

type RHFTextfieldProps = {
    name: string;
} & TextFieldProps;


export function RHFTextfield({helperText, defaultValue, disabled, ...props}: RHFTextfieldProps) {
    const {control} = useFormContext();

    return (<Controller
        control={control}
        name={props.name}
        defaultValue={defaultValue}

        render={({field, fieldState: {error}})=>(
            <TextField fullWidth {...props} {...field} error={!!error} helperText={error? error?.message : helperText} disabled={disabled} />
        )}
        />)
}