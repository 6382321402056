import {IconButton, InputAdornment, TextField, TextFieldProps, Tooltip} from "@mui/material";
import React from "react";
import {Link, LinkOff} from "@mui/icons-material";

export function LinkableTextfield(props: TextFieldProps) {
    const [linked, setLinked] = React.useState<boolean>(false);
    return <TextField {...props} disabled={linked} InputProps={{
        endAdornment: <InputAdornment position={"end"}><IconButton onClick={() => setLinked(!linked)}><Tooltip
            title={"Een gelinkt attribuut wordt automatisch geupdated van de bron."}>{!linked ? <Link/> :
            <LinkOff/>}</Tooltip></IconButton></InputAdornment>
    }}></TextField>
}